import { ItemsRepository } from 'viper';

class MsolPartnerCenterRepository extends ItemsRepository {
  constructor() {
    super({
      name: 'MsolPartnerCenterRepository'
    });
  }
}

export default new MsolPartnerCenterRepository();
