import { ObjectRepository } from 'viper';
import AzureCustomerOperations from './azure.customer.operations';

class AzureCustomerRepository extends ObjectRepository {
  constructor() {
    super({
      name: 'AzureCustomerRepository',
      OperationsClass: AzureCustomerOperations
    });
    this.updateDomain = this.updateDomain.bind(this);
  }

  updateDomain(change, cb) {
    this.pushUpdate('updateDomain', change, cb);
  }
}

export default new AzureCustomerRepository();
