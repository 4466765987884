import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import ActionPublisher from 'decorators/action-publisher';

class RadioList extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(evt) {
    const data = {
      field: this.props.field,
      type: Constants.actionTypes.CHANGE,
      value: evt.target.value
    };

    if (this.props.validator) {
      data.valid = this.props.validator(data);
    }

    this.publishAction(data);
  }

  renderItems() {
    return TP.array.map(
      (item) => {
        return (
          <FormControlLabel key={item.value} value={item.value} control={<Radio />} label={item.label} />
        );
      },
      this.props.items
    );
  }

  render() {
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">{this.props.label}</FormLabel>
        <RadioGroup
          name={this.props.field}
          value={`${this.props.value}`}
          onChange={this.onChange}
        >
          {
            this.renderItems()
          }
        </RadioGroup>
      </FormControl>
    );
  }
}

export default ActionPublisher({}, RadioList);
