// IMPORTS
import {
  ActionDispatcher,
  FocusStore,
  StateStore
} from 'viper';

import { AccountController } from 'service-layer/account';

import {
  QrUpdates
} from './mfa.settings.store';

// ACTIONS
const mfaSettingsRegistrationAction = ActionDispatcher.register('mfaSettingsRegistration');

// FUNCTIONS
const toFormData = (mfaForm) => {
  return {
    token: mfaForm.values.mfaCode
  };
};

const toRegisterFailure = (registerResponse) => {
  return {
    errorMessage: registerResponse.friendlyMessage,
    processing: false
  };
};

// STREAMS
const qrStateUpdates = QrUpdates
  .map(TP.object.pick(['qrCodeUrl']));

const formDataUpdates = mfaSettingsRegistrationAction
  .filter(TP.actions.isSubmit)
  .map(toFormData);

const registerRequestUpdates = formDataUpdates
  .flatMap(AccountController.register);

const successUpdates = registerRequestUpdates
  .skipErrors()
  .map(TP.func.always({ success: true }));

const registerViewUpdates = Bacon.mergeAll(
  qrStateUpdates,
  successUpdates,
  registerRequestUpdates.errors().mapError(toRegisterFailure)
)
  .scan({}, TP.object.merge);

const focusUpdates = qrStateUpdates
  .merge(registerRequestUpdates.errors().mapError(TP.func.identity))
  .map(TP.func.always({ focusKey: 'mfaSettingsRegistration', field: 'mfaCode' }));

// SUBSCRIBERS
registerViewUpdates.onValue(StateStore.publish('mfaSettingsRegistration'));
focusUpdates.onValue(FocusStore.setFocus);

// EXPORTS
