// IMPORTS
import { ActionDispatcher } from 'viper';

import { ConnectorsController } from 'service-layer/connectors';

// ACTIONS
const moduleconnectorsCustomerRenderedAction = ActionDispatcher.register('moduleserviceAzureAdCustomerServiceRendered');

// FUNCTIONS
const toCustomer = ({ customerId, serviceId, connectorData }) => {
  const data = {
    id: customerId,
    serviceId,
    connector: JSON.parse(connectorData)
  };

  return data;
};

// STREAMS
const loadUpdates = moduleconnectorsCustomerRenderedAction
  .filter(TP.object.prop('connectorData'))
  .map(toCustomer);

// SUBSCRIBERS
loadUpdates.onValue(ConnectorsController.getPrimaryAzureConnector);

// EXPORTS
