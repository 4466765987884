import ActionKeys from './action.keys';
import ActionTypes from './action.types';
import KeyCodes from './key.codes';
import ModuleKeys from './module.keys';

export default {
  actionKeys: ActionKeys,
  actionTypes: ActionTypes,
  keyCodes: KeyCodes,
  moduleKeys: ModuleKeys
};
