// IMPORTS
import { StateStore } from 'viper';

import { AzureCustomerRepository } from 'service-layer/connectors';

// ACTIONS

// FUNCTIONS
const getNewStepFromViewState = () => {
  const viewState = VB.readFromInput(window.connectorViewStateInput);
  return viewState && viewState.view;
};

const getNewView = (connectorData) => {
  if (connectorData.partnerCenterTier && connectorData.partnerCenterTier !== 'Tier1') {
    return 'connect';
  }

  switch (connectorData.newStep) {
    case 'create':
      return 'details';
    case 'connect':
      return 'connect';
    default:
      return getNewStepFromViewState() || 'new';
  }
};

const getView = (connectorData) => {
  switch (connectorData.connectorStatusId) {
    case 1:
      if (connectorData.statusId === 2) {
        return 'details';
      }
      return getNewView(connectorData);
    case 2:
      return 'connecting';
    default:
      return 'details';
  }
};

const toViewState = (connectorData) => {
  return {
    view: getView(connectorData),
    errorMessage: connectorData.errorMessage
  };
};

const writeToInput = (connector) => {
  VB.writeToInput(window.connectorDataInput, connector);
};

const writeToViewStateInput = (viewState) => {
  VB.writeToInput(window.connectorViewStateInput, viewState);
};

// STREAMS
const stateUpdates = AzureCustomerRepository
  .itemUpdates;

const viewStateUpdates = stateUpdates
  .map(toViewState);

// SUBSCRIBERS
stateUpdates
  .onValue(StateStore.publish('connectorsCustomersAzure'));
viewStateUpdates
  .onValue(StateStore.publish('connectorsView'));

AzureCustomerRepository.itemUpdates.onValue(writeToInput);
viewStateUpdates.onValue(writeToViewStateInput);

// EXPORTS
