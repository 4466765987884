import DataSource from './data.source';

class ItemDataSource extends DataSource {
  constructor() {
    super(
      {
        itemsKey: 'item',
        itemsDefault: () => { return {}; },
        hasKeyIndex: false
      }
    );
  }
}

export default ItemDataSource;
