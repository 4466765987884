import Actions from './actions';
import Arr from './array';
import Func from './func';
import Keys from './keys';
import Logic from './logic';
import Obj from './object';
import Validators from './validators';

export default {
  actions: Actions,
  array: Arr,
  func: Func,
  keys: Keys,
  logic: Logic,
  object: Obj,
  validators: Validators
};
