import { ActionDispatcher, Logger } from 'viper';

const ActionPublisherDecorator = TP.func.curry((config, Component) => {
  class ActionPublisher extends Component {
    publishAction(data) {
      const key = this.props.actionKey || this.props.defaultKey || config.actionKey;

      if (process.env.NODE_ENV !== 'production') {
        if (!key && !this.props.onPublish) {
          Logger.warning('[ActionPublisher] could not determine the key to publish to and als no onPublish');
        }
      }

      const sendData = data.id ? data : TP.object.assoc('id', this.props.id, data);
      key && ActionDispatcher.publish(key, sendData);
      this.props.onPublish && this.props.onPublish(sendData);
    }
  }

  ActionPublisher.displayName = Component.displayName || Component.name;

  return ActionPublisher;
});

export default ActionPublisherDecorator;
