import { Api } from 'viper';
import * as msal from '@azure/msal-browser';
import { v4 as uuidv4 } from 'uuid';

const changePasswordSync = (changePasswordData, cb) => {
  const request = Api.put('/api/account/password', changePasswordData);
  Api.addDefaultListeners(request, cb, 'Failed to change password');
};

const getAccountSync = (_, cb) => {
  const request = Api.get('/api/account');
  Api.addDefaultListeners(request, cb, 'Failed to get account details');
};

const getMFAConfigSync = (configData, cb) => {
  const url = `/api/mfa/config${configData.isReset ? '?isReset=true' : ''}`;
  const request = Api.get(url);
  Api.addDefaultListeners(request, cb, 'Failed to get mfa config');
};

const loginSync = (loginData, cb) => {
  const request = Api.post('/api/account/login', loginData);
  Api.addDefaultListeners(request, cb, 'Failed to login');
};

const loginAAD = () => {
  return Bacon.fromNodeCallback((cb) => {
    const request = Api.get('/api/azuread/settings');
    Api.addDefaultListeners(request, cb, 'Failed to get azure ad login settings');
  })
    .flatMap((azureClientSettings) => {
      const signInOptions = {
        scopes: [],
        state: uuidv4()
      };

      const replyUrl = window.location.port === ''
        ? `${window.location.protocol}//${window.location.hostname}${azureClientSettings.redirectUri}`
        : `${window.location.protocol}//${window.location.hostname}:${window.location.port}${azureClientSettings.redirectUri}`;


      const msalConfig = {
        auth: {
          authority: azureClientSettings.authority,
          clientId: azureClientSettings.clientId,
          redirectUri: replyUrl
        },
        cache: {
          cacheLocation: 'sessionStorage',
          storeAuthStateInCookie: false
        }
      };

      const msalClient = new msal.PublicClientApplication(msalConfig);

      return Bacon.fromNodeCallback((cb) => {
        msalClient.loginPopup(signInOptions)
          .then((result) => { cb(null, result); })
          .catch((err) => { cb(err); });
      })
        .flatMap((result) => {
          const valid = (result.state === signInOptions.state);
          return valid ? Bacon.once({
            accountIdentifier: result.account.localAccountId,
            accountName: result.account.username,
            idToken: result.idToken
          }) : Bacon.Error({ friendlyMessage: 'Failed to login Azure AD' });
        })
        .flatMap((loginAadData) => {
          return Bacon.fromNodeCallback((cb) => {
            const request = Api.post('/api/account/loginazuread', loginAadData);
            Api.addDefaultListeners(request, cb, 'Failed to login Azure AD');
          });
        });
    });
};

const registerSync = (registerData, cb) => {
  const request = Api.post('/api/mfa/register', registerData);
  Api.addDefaultListeners(request, cb, 'Failed to register');
};

const verifySync = (verifyData, cb) => {
  const request = Api.post('/api/mfa/validate', verifyData);
  Api.addDefaultListeners(request, cb, 'Failed to verify login token');
};

export default {
  changePasswordSync,
  getAccountSync,
  getMFAConfigSync,
  loginSync,
  loginAAD,
  registerSync,
  verifySync
};
