// IMPORTS
import AzureCustomerRepository from '../repositories/azure.customer.repository';

import ApiController from './connectors.api.controller';

// events
const provisionSaveErrors = new Bacon.Bus();
const loadErrors = new Bacon.Bus();
const connectErrors = new Bacon.Bus();

// FUNCTIONS
const editPrimaryAzureConnector = (change, cb) => {
  AzureCustomerRepository.edit(change, cb);
};

const updateAzureDomain = (change, cb) => {
  AzureCustomerRepository.updateDomain(change, cb);
};

const getPrimaryAzureConnector = (connectorData, cb) => {
  const connector = TP.object.merge(connectorData.connector, { customerId: connectorData.id, loaded: true });
  AzureCustomerRepository.refresh(connector, cb);
};

const startPolling = (connector) => {
  Bacon.interval(1000, connector)
    .flatMapLatest(ApiController.getStatusAsync)
    .takeWhile((item) => {
      return item.data.isProvisioning;
    })
    .last()
    .onValue(() => {
      getPrimaryAzureConnector({ id: connector.customerId });
    });
};

const verifyDomain = (domain, cb) => {
  ApiController.verifyDomain(domain, (err, response) => {
    if (err) {
      cb && cb(err, response);
      AzureCustomerRepository.edit({ isVerifying: false }, null);
      return;
    }

    AzureCustomerRepository.edit({ domainAvailable: response.data.isAvailable, isVerifying: false }, cb);
  });
};

const provision = (_, cb) => {
  AzureCustomerRepository
    .itemUpdates
    .first()
    .onValue((item) => {
      AzureCustomerRepository.edit({ inProgress: true }, null);
      ApiController.provision(item, (err, response) => {
        if (err) {
          provisionSaveErrors.push({ err, response });
          cb && cb(err, response);
          return;
        }
        cb && cb(null, response.data);
        startPolling(
          TP.object.assoc('customerId', item.customerId, response.data)
        );
      });
    });
};

const connect = (connectData, cb) => {
  AzureCustomerRepository.edit({ inProgress: true }, null);
  ApiController.connect(connectData, (err, response) => {
    if (err) {
      connectErrors.push({ err, response });
      cb && cb(err, response);
      return;
    }

    cb && cb(null, response.data);

    startPolling(TP.object.assoc('customerId', connectData.customerId, response.data));
  });
};

// EXPORTS
export default {
  events: {
    connectErrors: connectErrors.toEventStream(),
    loadErrors: loadErrors.toEventStream(),
    provisionSaveErrors: provisionSaveErrors.toEventStream()
  },
  editPrimaryAzureConnector,
  getPrimaryAzureConnector,
  getPrimaryAzureConnectorAsync: TP.func.toAsync(getPrimaryAzureConnector),
  connect,
  verifyDomain,
  provision,
  updateAzureDomain
};
