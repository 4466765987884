import InputAdornment from '@material-ui/core/InputAdornment';

import StateReader from 'decorators/state-reader';

import Button from 'components/button';
import TextInput from 'components/text-input';
import Text from 'components/text';

import {
  row,
  verifyButton,
  labelColumn,
  valueColumn
} from '../styles/connector.azure.scss';

class AzureDomainInput extends React.Component {
  getAzureDomainPrefix() {
    return this.state.currentState.azureDomain && this.state.currentState.azureDomain.replace('.onmicrosoft.com', '');
  }

  renderViewOnly() {
    return (
      <div className={row}>
        <div className={row} />
        <Text
          type={Text.FORM_LABEL_BOLD}
          domComponent="div"
          className={labelColumn}
          contentId="azureDomain"
          text="Azure Domain"
        />
        <Text
          type={Text.FORM_FIELD_VALUE}
          domComponent="div"
          className={valueColumn}
          text={this.state.currentState.azureDomain}
        />
      </div>
    );
  }

  render() {
    if (this.state.currentState.connectorStatusId === 3) {
      return this.renderViewOnly();
    }

    return (
      <div className={row}>
        <TextInput
          actionKey="customerConnectorsAzureDomain"
          contentId="msDomainCode"
          label="MS Domain Code"
          field="azureDomain"
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">.onmicrosoft.com</InputAdornment>
          }}
          value={this.getAzureDomainPrefix()}
        />
        <div className={verifyButton}>
          <Button
            actionKey="customerConnectorsAzureDomainVerify"
            type="primary"
            variant="text"
            text="Verify"
            contentId="verify"
            disabled={this.state.currentState.isVerifying}
          />
        </div>
        <div>
          <Text
            visible={this.state.currentState.domainAvailable === false}
            type={Text.WARNING}
            contentId="msDomainNotAvailable"
            text="Domain is not available"
          />
          <Text
            visible={this.state.currentState.domainAvailable === true}
            type={Text.INFO}
            contentId="msDomainAvailable"
            text="Domain is available"
          />
        </div>
      </div>
    );
  }
}

export default StateReader({}, AzureDomainInput);
