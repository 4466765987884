import TextField from '@material-ui/core/TextField';

import ActionPublisher from 'decorators/action-publisher';
import Focusable from 'decorators/focusable';

class TextInput extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onBlur(e) {
    this.publishValue(e, Constants.actionTypes.BLUR);
  }

  onChange(e) {
    this.publishValue(e, Constants.actionTypes.CHANGE);
  }

  onKeyUp(e) {
    if (TP.keys.isEnter(e)) {
      this.onSubmit(e);
    }
    if (TP.keys.isEscape(e)) {
      this.onBlur(e);
    }
  }

  onSubmit(e) {
    this.publishValue(e, Constants.actionTypes.SUBMIT);
  }

  publishValue(e, type) {
    const data = {
      field: this.props.field,
      type,
      value: e.target.value
    };

    if (this.props.validator) {
      data.valid = this.props.validator(data);
    }

    this.publishAction(data);
  }

  render() {
    return (
      <div className={this.props.containerClassName}>
        <TextField
          id={this.props.id}
          inputRef={this.focusableRef}
          className={this.props.className}
          label={this.props.label}
          fullWidth={this.props.fullWidth}
          margin="normal"
          type={this.props.type || 'text'}
          error={this.props.error}
          required={this.props.required}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onKeyUp={this.onKeyUp}
          autoFocus={this.props.autoFocus}
          InputProps={this.props.InputProps}
          value={this.props.value}
          InputLabelProps={{
            shrink: this.props.value !== '' && this.props.value !== null
          }}
        />
      </div>
    );
  }
}

export default TP.func.pipe(
  ActionPublisher({}),
  Focusable({})
)(TextInput);
