import Paper from '@material-ui/core/Paper';

import Theme from 'decorators/theme';
import StateReader from 'decorators/state-reader';

import Spinner from 'components/spinner';
import Text from 'components/text';

import CustomerConnectorAzure from './customer.connector.azure';
import AzureConnectorNew from './azure.connector.new';
import AzureConnectorConnect from './azure.connector.connect';
import AzureConnectorConnecting from './azure.connector.connecting';

import {
  spinnerContainer,
  spinnerText,
  errorContainer,
  errorText
} from '../styles/connector.azure.scss';

class CustomerConnectors extends React.Component {
  componentDidMount() {
    VB.resizeIframe();
  }

  componentDidUpdate() {
    VB.resizeIframe();
  }

  renderError() {
    if (!this.state.currentState.errorMessage) return null;

    return (
      <Paper className={errorContainer}>
        <Text className={errorText} type={Text.NORMAL} text={this.state.currentState.errorMessage} />
      </Paper>
    );
  }

  renderLoading() {
    return (
      <Paper className={spinnerContainer}>
        <Spinner key="loading" color="#0000FF" />
        <Text className={spinnerText} type={Text.NORMAL} text="Connector info is loading..." />
      </Paper>
    );
  }

  renderProvisioning() {
    return (
      <Paper className={spinnerContainer}>
        <Spinner key="provisioning" />
        <Text className={spinnerText} type={Text.NORMAL} text="Connector provisioning in progress..." />
      </Paper>
    );
  }

  renderView() {
    switch (this.state.currentState.view) {
      case 'new':
        return (
          <AzureConnectorNew />
        );
      case 'connecting':
        return (
          <AzureConnectorConnecting defaultKey="connectorsCustomersAzure" />
        );
      case 'provisioning':
        return this.renderProvisioning();
      case 'error':
        return this.renderError();
      case 'connect':
        return (
          <AzureConnectorConnect defaultKey="azureConnectorConnect" />
        );
      case 'details':
        return (
          <CustomerConnectorAzure defaultKey="connectorsCustomersAzure" />
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <div>
        {this.renderView()}
      </div>
    );
  }
}

export default TP.func.pipe(
  Theme({}),
  StateReader({ stateKey: 'connectorsView', defaultView: 'loading' })
)(CustomerConnectors);
