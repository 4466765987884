import ActionPublisher from 'decorators/action-publisher';
import Focus from 'decorators/focus';
import Form from 'decorators/form';
import StateReader from 'decorators/state-reader';
import { ActionDispatcher } from 'viper';
import TextInput from 'components/text-input';
import Button from 'components/button';
import CapsLockIndicator from 'components/capslock-indicator';

import {
  footer,
  formContainer,
  heading,
  loginInputs,
  azuread
} from '../styles/login.form.scss';

/* eslint-disable react/no-danger */
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.loginAzure = this.loginAzure.bind(this);
  }

  createMarkup() {
    const elem = document.getElementById(this.state.currentState.loginPageText);

    if (!elem) {
      return null;
    }

    return {
      __html: elem.innerHTML
    };
  }

  loginAzure() {
    if (!this.state.currentState.processLoginAAD) {
      ActionDispatcher.publish('loginAzureAD', { id: this.props.id, eventSource: 'submit' });
    }
  }

  renderCapsLockIndicatorText() {
    return (
      <CapsLockIndicator>
        {(active) => {
          let span = null;
          if (active) {
            span = <span style={{ color: 'red' }}>Caps lock is on</span>;
          }

          return span;
        }
        }
      </CapsLockIndicator>
    );
  }

  renderError() {
    if (!this.state.currentState.loginFailedMessage) return null;
    return (
      <div style={{ color: 'red' }}>
        {this.state.currentState.loginFailedMessage}
      </div>
    );
  }

  renderReset() {
    return (
      <Button
        type="secondary"
        variant="text"
        text="Forgot Password"
        defaultKey="loginFormForgotPassword"
      />
    );
  }

  renderLoginText() {
    if (!this.state.currentState.showLoginText) {
      return null;
    }

    return <div dangerouslySetInnerHTML={this.createMarkup()} />;
  }

  renderAzureAdButton() {
    if (!this.state.currentState.showAzureADButton) return null;

    return (
      <div
        style={{ float: 'left' }}
        role="button"
        tabIndex={0}
        className={`${azuread} azure-ad-login-button`}
        onClick={this.loginAzure}
        onKeyPress={this.loginAzure}
      >
        <img src="/img/ms-symbol.svg" alt="Microsoft Logo" />
        Sign in with Microsoft
      </div>
    );
  }

  render() {
    return (
      <div className={formContainer}>
        <h2
          className={heading}
        >
          LOGIN
        </h2>
        {this.renderFormField(
          TextInput,
          'username',
          true,
          TP.validators.notEmpty,
          { className: loginInputs, label: 'Username' }
        )}
        {this.renderFormField(
          TextInput,
          'password',
          true,
          TP.validators.notEmpty,
          { className: loginInputs, label: 'Password', type: 'password' }
        )}
        {this.renderCapsLockIndicatorText()}
        {this.renderError()}
        <div className={footer}>
          {this.renderAzureAdButton()}
          <div style={{ float: 'right' }}>
            {this.state.currentState.showResetPassword && this.renderReset()}
            <Button
              disabled={this.isProcessing()}
              onPublish={this.onSubmit}
              type="primary"
              variant="contained"
              text="Login"
            />
          </div>
        </div>
        {this.renderLoginText()}
      </div>
    );
  }
}

export default TP.func.pipe(
  StateReader({}),
  ActionPublisher({}),
  Form({}),
  Focus({})
)(Login);
