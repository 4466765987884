// IMPORTS
import { ActionDispatcher, StateStore } from 'viper';

import { MsolPartnerCenterController, MsolPartnerCenterRepository, MsolRegionsRepository } from 'service-layer/msol';

// ACTIONS
const selectAction = ActionDispatcher.register('partnerCenterSelect');
const newAction = ActionDispatcher.register('msolPartnerCenterNew');

// FUNCTIONS
const toState = (update) => {
  return {
    items: update.items,
    selected: update.selected || ''
  };
};

const toSelectUpdate = (action) => {
  return {
    id: action.id || action.value
  };
};

const toViewState = (pcData) => {
  if (!pcData.loaded) {
    return { view: '' };
  }
  return {
    view: pcData.selected ? 'connection' : 'connections'
  };
};

const toConnectionView = () => {
  return {
    view: 'connection'
  };
};

const toRegionstate = (regionData) => {
  return {
    items: regionData.items
  };
};

// STREAMS
const stateUpdates = MsolPartnerCenterRepository
  .itemsUpdates
  .map(toState);

const viewUpdates = MsolPartnerCenterRepository
  .itemsUpdates
  .map(toViewState)
  .toEventStream()
  .merge(newAction.map(toConnectionView));

const selectUpdates = selectAction.map(toSelectUpdate);

const regionUpdates = MsolRegionsRepository.itemsUpdates
  .map(toRegionstate);

// SUBSCRIBERS
stateUpdates.onValue(StateStore.publish('msolPartnerCenters'));
viewUpdates.onValue(StateStore.publish('partnerCenterConnectionView'));
selectUpdates.onValue(MsolPartnerCenterController.select);
regionUpdates.onValue(StateStore.publish('msolRegions'));

// EXPORTS
