const isBackspace = (evt) => {
  return evt.keyCode === Constants.keyCodes.BACKSPACE;
};

const isEnter = (evt) => {
  return evt.keyCode === Constants.keyCodes.ENTER;
};

const isEscape = (evt) => {
  return evt.keyCode === Constants.keyCodes.ESCAPE;
};

export default {
  isBackspace,
  isEnter,
  isEscape
};
