import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ActionPublisher from 'decorators/action-publisher';
import StateReader from 'decorators/state-reader';

import Button from 'components/button';

class MsolOfferManagementSync extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onSubmit() {
    this.publishAction({ type: Constants.actionTypes.SUBMIT });
  }

  onCancel() {
    this.publishAction({ type: Constants.actionTypes.CANCEL });
  }

  render() {
    return (
      <Dialog open={this.state.currentState.visible} onClose={this.onCancel}>
        <DialogTitle id="form-dialog-title">Sync offers from Partner Center</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will get the latest catalog info from partner center. This process takes a while to complete please leave your browser window open.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onPublish={this.onCancel}
            type="primary"
            variant="text"
            text="cancel"
          />
          <Button
            onPublish={this.onSubmit}
            type="primary"
            variant="contained"
            text="Sync"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

export default TP.func.pipe(
  ActionPublisher({}),
  StateReader({ defaultState: { visible: false } })
)(MsolOfferManagementSync);
