import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import deepOrange from '@material-ui/core/colors/deepOrange';
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: deepOrange,
    secondary: red
  }
});

const ThemeDecorator = (config, Component) => {
  class Theme extends Component {
    render() {
      return (
        <MuiThemeProvider theme={config.theme || theme}>
          {super.render()}
        </MuiThemeProvider>
      );
    }
  }

  return Theme;
};

export default TP.func.curry(ThemeDecorator);
