// IMPORTS
import { ActionDispatcher, Api, StateStore } from 'viper';

import { AzureCustomerRepository } from 'service-layer/connectors';

// ACTIONS
const searchAction = ActionDispatcher.register('azureConnectorConnectSearchText');
const connectAction = ActionDispatcher.register('azureConnectorConnectSelect');
const connectRetryAction = ActionDispatcher.register('azureConnectorConnectRetry');

// FUNCTIONS
const toSearchRequestData = (connector, search) => {
  return {
    partnerCenterId: connector.partnerCenterId,
    search: search.value
  };
};

const toRequest = (searchRequestData) => {
  return Api.get(`/api/service/msol/management/partner-centers/${searchRequestData.partnerCenterId}/contracts?search=${searchRequestData.search}`);
};

const toEmpty = () => {
  return {
    items: [],
    loaded: false,
    loading: false
  };
};

const toSearchResponse = (response) => {
  return {
    items: response.data,
    loaded: true,
    loading: false
  };
};

const toConnectData = (connector, connectActionData) => {
  return {
    azureCustomerId: connectActionData.id,
    customerId: connector.customerId
  };
};

const toConnectRetryData = TP.object.pick(['customerId', 'azureCustomerId']);

// STREAMS
const searchStringUpdates = searchAction
  .skipDuplicates(TP.object.eqProps('value'))
  .debounce(200);

const clearUpdates = searchStringUpdates
  .filter(TP.object.not.prop('value'))
  .map(toEmpty);

const searchUpdates = searchStringUpdates
  .filter(TP.object.prop('value'));

const searchRequestUpdates = Bacon.when(
  [AzureCustomerRepository.itemUpdates.toProperty(), searchUpdates.toEventStream()], toSearchRequestData
)
  .flatMapLatest(toRequest)
  .map(toSearchResponse);


const stateUpdates = Bacon.mergeAll(
  searchRequestUpdates.skipErrors(),
  clearUpdates,
  searchUpdates.map({ loading: true })
)
  .scan({}, TP.object.merge);

const connectUpdates = Bacon.when(
  [AzureCustomerRepository.itemUpdates.toProperty(), connectAction], toConnectData,
  [AzureCustomerRepository.itemUpdates.toProperty(), connectRetryAction], toConnectRetryData
);

const writeToInputAndProvision = (connector) => {
  VB.writeToInput(window.connectorConnectDataInput, connector);
  VB.clickProvision();
};

// SUBSCRIBERS
stateUpdates.onValue(StateStore.publish('azureConnectorConnect'));
connectUpdates.onValue(writeToInputAndProvision);

// EXPORTS
