// this file reduces the amount of imports and therefore speeds up app start up
// the way webpack imports code involves a whole lot of functions
// in general common node_modules should be imported here and exported onto global ref
import Bacon from 'baconjs';
import Constants from 'constants';
import React from 'react';
import TP from 'tiger-paw';

window.Bacon = Bacon;
window.Constants = Constants;
window.React = React;
window.TP = TP;
window.VB = require('viper-bridge').default;
