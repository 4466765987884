import StateReader from 'decorators/state-reader';

import Button from 'components/button';

import {
  row,
  buttonRow,
  button
} from '../styles/connector.azure.scss';

class AzureConnectorNew extends React.Component {
  render() {
    return (
      <div>
        <div className={row}>
          Please select from the options below to setup this customer with azure.
        </div>
        <div className={buttonRow}>
          <div className={button}>
            <Button
              defaultKey="azureConnectorCreate"
              id="create"
              type="primary"
              variant="contained"
              text="Create New Tenant"
              contentId="createNewTenant"
            />
          </div>
          <div className={button}>
            <Button
              defaultKey="azureConnectorConnect"
              id="connect"
              type="primary"
              variant="contained"
              text="Connect To Existing Tenant"
              contentId="connectToExistingTenant"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default StateReader({}, AzureConnectorNew);
