// IMPORTS
import { ActionDispatcher } from 'viper';

import { MsolPartnerCenterController } from 'service-layer/msol';

// ACTIONS
const connectionModuleRenderedAction = ActionDispatcher.register(`module${Constants.moduleKeys.SERVICE_MSOL_PARTNERCENTER}Rendered`);
const offerModuleRenderedAction = ActionDispatcher.register(`module${Constants.moduleKeys.SERVICE_MSOL_OFFER_MANAGEMENT}Rendered`);

// FUNCTIONS

// STREAMS
const loadUpdates = connectionModuleRenderedAction
  .merge(offerModuleRenderedAction);

// SUBSCRIBERS
loadUpdates.onValue(MsolPartnerCenterController.load);
loadUpdates.onValue(MsolPartnerCenterController.loadRegions);

// EXPORTS
