import Paper from '@material-ui/core/Paper';

import StateReader from 'decorators/state-reader';

import Button from 'components/button';
import TextInput from 'components/text-input';

import {
  searchContainerDivs,
  customerContainer,
  selectButton
} from '../styles/azure.connector.connnect.scss';

class AzureConnectorConnect extends React.Component {
  constructor(props) {
    super(props);
    this.renderResult = this.renderResult.bind(this);
  }

  componentDidMount() {
    VB.resizeIframe();
  }

  componentDidUpdate() {
    VB.resizeIframe();
  }

  renderResult(item) {
    return (
      <Paper key={item.azureCustomerId} className={customerContainer}>
        {item.name}
        <br />
        {item.domainName}
        <div className={selectButton}>
          <Button
            id={item.azureCustomerId}
            actionKey="azureConnectorConnectSelect"
            text="Select"
            type="primary"
            variant="contained"
            disableOnFirstClick
          />
        </div>
      </Paper>
    );
  }

  renderResults() {
    if (!this.state.currentState.items) return null;

    return TP.array.map(
      this.renderResult,
      this.state.currentState.items
    );
  }

  render() {
    return (
      <div>
        <div>
          Search for an existing tenant by name or domain name
        </div>
        <div className={searchContainerDivs}>
          <TextInput
            defaultKey="azureConnectorConnectSearchText"
            autoFocus
            fullWidth
            label="Search string..."
          />
        </div>
        <div>
          {this.state.currentState.loading ? 'Loading new results...' : ''}
        </div>
        <div>
          <div>
            {this.renderResults()}
          </div>
        </div>
      </div>
    );
  }
}

export default TP.func.pipe(
  StateReader({ })
)(AzureConnectorConnect);
