import RepositoryOperationBase from './repository.operation.base';

class ObjectRepositoryOperationBase extends RepositoryOperationBase {
  constructor(config) {
    super(config);

    this.refresh = this.refresh.bind(this);
  }

  refresh(currentDs, change) {
    return {
      item: change.data,
      added: [change.data],
      updated: [],
      removed: [],
      reordered: false,
      change
    };
  }

  edit(currentDs, change) {
    const item = TP.object.merge(currentDs.getCurrentData().item || {}, change.data);
    return {
      item,
      added: [],
      updated: [item],
      removed: [],
      reordered: false,
      change
    };
  }
}

export default ObjectRepositoryOperationBase;
