/* eslint-disable prefer-arrow-callback */
import {
  curry,
  has,
  isEmpty,
  path,
  pick,
  prop,
  propEq,
  merge,
  values
} from 'rambda';

// efficiently adds a property to an object returning a new instance
const assoc = curry(function assoc(key, value, obj) {
  if (!obj) return { [key]: value };

  const result = {};

  for (const propKey in obj) { // eslint-disable-line guard-for-in, no-restricted-syntax
    result[propKey] = obj[propKey];
  }

  result[key] = value;
  return result;
});

// efficiently removes a property from an object returning a new instance
const dissoc = curry(function dissoc(key, obj) {
  if (!obj || !obj[key]) return obj;

  const result = {};

  for (const propKey in obj) { // eslint-disable-line guard-for-in, no-restricted-syntax
    if (propKey !== key) {
      result[propKey] = obj[propKey];
    }
  }

  return result;
});

const eqProps = curry(function eqProps(key, obj1, obj2) {
  return obj1[key] === obj2[key];
});

const fromKeys = curry(function fromKeys(initValue, keysArray) {
  const result = {};

  for (let i = 0; i < keysArray.length; i += 1) {
    result[keysArray[i]] = initValue;
  }

  return result;
});

const fromFormField = (formActionUpdate) => {
  return {
    [formActionUpdate.field]: formActionUpdate.value
  };
};

const fromValue = curry(function fromValue(key, source) {
  return {
    [key]: source.value
  };
});

const propOr = curry(function propOr(defaultValue, key, obj) {
  return obj[key] !== undefined ? obj[key] : defaultValue;
});

// NOT FUNCTIONS
const notIsEmpty = (value) => {
  return !isEmpty(value);
};

const notProp = curry(function notProp(key, obj) {
  return !obj[key];
});

const notPropEq = curry(function notPropEq(key, value, obj) {
  return obj[key] !== value;
});

export default {
  not: {
    isEmpty: notIsEmpty,
    prop: notProp,
    propEq: notPropEq
  },
  assoc,
  dissoc,
  eqProps,
  fromFormField,
  fromKeys,
  fromValue,
  has,
  isEmpty,
  merge,
  path,
  pick,
  prop,
  propEq,
  propOr,
  values
};
