import { ItemsRepository } from 'viper';

class MsolOffersRepository extends ItemsRepository {
  constructor() {
    super({
      name: 'MsolOffersRepository'
    });
  }
}

export default new MsolOffersRepository();
