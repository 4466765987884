import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ExpansionPanel from 'components/expansion-panel';
import Checkbox from 'components/checkbox';

import AzureDomainInput from './azure.domain.input';

import {
  row
} from '../styles/connector.azure.scss';

class AzureConnectorDomains extends React.Component {
  renderDomains() {
    const domains = TP.array.map(
      (domain) => {
        return (
          <TableRow key={domain.id}>
            <TableCell>
              <Checkbox
                defaultKey="customerAzureDomain"
                id={domain.id}
                checked={domain.inAzure}
                disabled={domain.attributes && domain.attributes.isReadOnly}
              />
            </TableCell>
            <TableCell>
              {domain.domainName}
              {domain.attributes.status === 'Verified'
                ? (
                  <span style={{ color: 'green' }}>
                    &nbsp;(Verified)
                  </span>
                )
                : null}
              {domain.attributes.verificationCode
                ? (
                  <div>
                    Domain Verification Code: {domain.attributes.verificationCode}
                  </div>
                )
                : null}
            </TableCell>
            {this.props.isAdfsEnabled
              ? (
                <TableCell>
                  <Checkbox
                    defaultKey="customerAzureDomainAdfs"
                    id={domain.id}
                    checked={domain.attributes && domain.attributes.isAdfsEnabled}
                    disabled={(domain.attributes && domain.attributes.isReadOnly) || (domain.attributes && domain.attributes.status !== 'Verified')}
                  />
                </TableCell>
              )
              : null}
          </TableRow>
        );
      },
      this.props.domains
    );

    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>In Azure</TableCell>
            <TableCell>Domain Name</TableCell>
            {
              this.props.isAdfsEnabled
                ? <TableCell>Adfs Enabled</TableCell>
                : null
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {domains}
        </TableBody>
      </Table>
    );
  }

  render() {
    return (
      <ExpansionPanel
        expanded={this.props.expanded}
        title="Domains"
        defaultKey="connectorExpand"
        id="domainsExpanded"
      >
        <div>
          <AzureDomainInput defaultKey="customerConnectorAzureDomains" />
          <div className={row}>
            {this.renderDomains()}
          </div>
        </div>
      </ExpansionPanel>
    );
  }
}

export default AzureConnectorDomains;
