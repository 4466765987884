// IMPORTS
import { ActionDispatcher } from 'viper';

import { ConnectorsController } from 'service-layer/connectors';

// ACTIONS
const createAction = ActionDispatcher.register('azureConnectorCreate');
const connectAction = ActionDispatcher.register('azureConnectorConnect');

// FUNCTIONS
const toNewAction = (action) => {
  return {
    newStep: action.id
  };
};

// STREAMS
const newStepUpdates = createAction.merge(connectAction).map(toNewAction);

// SUBSCRIBERS
newStepUpdates.onValue(ConnectorsController.editPrimaryAzureConnector);

// EXPORTS
