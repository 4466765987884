import StateReader from 'decorators/state-reader';

import Select from 'components/select';

import {
  partnerCenterSelect
} from '../styles/offer.management.scss';

class MsolOfferPartnerCenterSelection extends React.Component {
  render() {
    return (
      <Select
        defaultKey="partnerCenterSelect"
        label="Partner Center Connection"
        selectDefaultText="Select Partner Center"
        options={this.state.currentState.items}
        formSelectClassName={partnerCenterSelect}
        field="id"
        value={this.state.currentState.selected}
      />
    );
  }
}

export default StateReader({}, MsolOfferPartnerCenterSelection);
