import { ItemsRepository } from 'viper';

class MsolRegionsRepository extends ItemsRepository {
  constructor() {
    super({
      name: 'MsolRegionsRepository'
    });
  }
}

export default new MsolRegionsRepository();
