import { ModuleRepository } from 'viper';

import './stores/msol.load.store';

import './stores/partnercenter.store';
import './stores/connection.save.store';
import './stores/partnercenter.edit.store';

import './stores/msol.offers.state.store';
import './stores/msol.offers.store';
import './stores/msol.offer.edit.store';
import './stores/msol.offer.sync.store';

import './stores/alert.dialog.show.hide.store';

import MsolPartnerCenter from './components/msol.partnercenter';
import MsolOfferManagement from './components/msol.offer.management';

ModuleRepository.register(Constants.moduleKeys.SERVICE_MSOL_PARTNERCENTER, {
  component: MsolPartnerCenter
});

ModuleRepository.register(Constants.moduleKeys.SERVICE_MSOL_OFFER_MANAGEMENT, {
  component: MsolOfferManagement
});
