import {
  heading1,
  heading2,
  formLabelBold,
  formFieldValue,
  warning
} from '../styles/text.scss';

class Text extends React.Component {
  getClassName() {
    let typeClassName;

    switch (this.props.type) {
      case Text.HEADING_1:
        typeClassName = heading1;
        break;
      case Text.HEADING_2:
        typeClassName = heading2;
        break;
      case Text.FORM_LABEL_BOLD:
        typeClassName = formLabelBold;
        break;
      case Text.FORM_FIELD_VALUE:
        typeClassName = formFieldValue;
        break;
      case Text.WARNING:
        typeClassName = warning;
        break;
      default:
        typeClassName = '';
    }

    return `${typeClassName} ${this.props.className || ''}`;
  }

  getText() {
    return this.props.text || this.props.children;
  }

  render() {
    if (this.props.visible === false) {
      return null;
    }
    const Component = this.props.domComponent || 'span';
    return React.createElement(Component, { className: this.getClassName() }, this.getText());
  }
}

Text.HEADING_1 = 'heading1';
Text.HEADING_2 = 'heading2';
Text.FORM_LABEL_BOLD = 'formLabelBold';
Text.FORM_FIELD_VALUE = 'formFieldValue';
Text.WARNING = 'warning';

export default Text;
