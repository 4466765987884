import DataSource from './data.source';

class ItemsDataSource extends DataSource {
  constructor() {
    super({ });
    this.getItem = this.getItem.bind(this);
    this.getItems = this.getItems.bind(this);
    this.getItemIndex = this.getItemIndex.bind(this);
    this.getSelected = this.getSelected.bind(this);
  }

  getItem(id) {
    const idx = this.dsData.idIndex[id];
    return idx >= 0 ? this.dsData.items[idx] : null;
  }

  getItems() {
    return this.dsData.items || [];
  }

  getItemIndex(id) {
    const idx = this.dsData.idIndex[id];
    return idx >= 0 ? idx : -1;
  }

  getSelected() {
    return this.getItem(this.selected);
  }
}

export default ItemsDataSource;
