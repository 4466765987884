// IMPORTS
import { ActionDispatcher } from 'viper';

import { ConnectorsController, AzureCustomerRepository } from 'service-layer/connectors';

// ACTIONS
const expandAction = ActionDispatcher.register('connectorExpand');

// FUNCTIONS
const isRefresh = (rawConnectorUpdate) => {
  return rawConnectorUpdate.dsData.added && rawConnectorUpdate.dsData.added.length;
};

const toRefreshExpandState = (rawConnectorUpdate) => {
  const isNew = rawConnectorUpdate.item.connectorStatusId === 1;
  return {
    overviewExpanded: !isNew,
    domainsExpanded: isNew,
    customerExpanded: isNew,
    settingsExpanded: isNew,
    termsExpanded: !isNew && !rawConnectorUpdate.item.tcAccepted
  };
};

const toExpand = (action) => {
  return {
    [action.id]: action.expanded
  };
};

// STREAMS
const refreshUpdates = AzureCustomerRepository
  .rawUpdates
  .filter(isRefresh)
  .map(toRefreshExpandState);

const expandUpdates = Bacon.mergeAll(
  refreshUpdates,
  expandAction.map(toExpand)
);

// SUBSCRIBERS
expandUpdates.onValue(ConnectorsController.editPrimaryAzureConnector);
// EXPORTS
