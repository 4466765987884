import { ModuleRepository } from 'viper';

import './stores/spotlight.show.hide.store';
import './stores/spotlight.text.store';

import SpotlightSearch from './components/spotlight-search';

ModuleRepository.register(Constants.moduleKeys.SPOTLIGHT_SEARCH, {
  component: SpotlightSearch
});
