// IMPORTS
import {
  ActionDispatcher,
  StateStore
} from 'viper';

import {
  MsolPartnerCenterRepository,
  MsolOffersController
} from 'service-layer/msol';

// ACTIONS
const syncAction = ActionDispatcher.register('msolOfferSync');
const syncConfirmAction = ActionDispatcher.register('msolOfferSyncConfirm');

// FUNCTIONS
const toVisible = () => { return { visible: true }; };
const toHidden = () => { return { visible: false }; };

const getSelected = (partnerCenterDs) => {
  return partnerCenterDs.getSelected();
};

// STREAMS
const visibleUpdates = syncAction.map(toVisible)
  .merge(syncConfirmAction.map(toHidden));

const syncUpdates = syncConfirmAction
  .filter(TP.actions.isSubmit);

const partnerCenterSyncUpdates = Bacon.when(
  [
    MsolPartnerCenterRepository.itemsUpdates.toProperty(),
    syncUpdates
  ],
  getSelected
);

// SUBSCRIBERS
visibleUpdates.onValue(StateStore.publish('msolOfferSyncConfirm'));
partnerCenterSyncUpdates.onValue(MsolOffersController.syncOffers);

// EXPORTS
