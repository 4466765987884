import ActionPublisher from 'decorators/action-publisher';

import { default as MUIButton } from '@material-ui/core/Button'; // eslint-disable-line import/no-named-default

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = { disabled: false };
  }

  handleClick(e) {
    if (this.props.disableOnFirstClick) {
      this.setState({ disabled: true });
    }
    if (this.props.onClick) {
      this.props.onClick(e);
    } else {
      e.preventDefault();
      this.publishAction({ evenSource: 'submit' });
    }
  }

  renderIcon() {
    if (!this.props.icon) return null;
    return <i className="material-icons">{this.props.icon}</i>;
  }

  render() {
    return (
      <MUIButton
        disabled={this.state.disabled || this.props.disabled}
        onClick={this.handleClick}
        variant={this.props.variant}
        color={this.props.type}
        className={this.props.className}
        style={this.props.style}
      >
        {this.renderIcon()}{this.props.text}
      </MUIButton>
    );
  }
}

Button.defaultProps = {
  type: 'button'
};

export default ActionPublisher({}, Button);
