/* eslint-disable prefer-arrow-callback */
import { curry } from 'rambda';

import Obj from './object';

const isBlur = (obj) => {
  return Obj.propEq('type', Constants.actionTypes.BLUR, obj);
};

const isChange = (obj) => {
  return Obj.propEq('type', Constants.actionTypes.CHANGE, obj);
};

const isSubmit = (obj) => {
  return Obj.propEq('type', Constants.actionTypes.SUBMIT, obj);
};

const isCancel = (obj) => {
  return Obj.propEq('type', Constants.actionTypes.CANCEL, obj);
};

const isClose = (obj) => {
  return Obj.propEq('type', Constants.actionTypes.CLOSE, obj);
};

const isOpen = (obj) => {
  return Obj.propEq('type', Constants.actionTypes.OPEN, obj);
};

const notOneOf = curry(function notOneOf(typeObj, obj) {
  return !typeObj[obj.type];
});

export default {
  not: {
    oneOf: notOneOf
  },
  isBlur,
  isCancel,
  isChange,
  isSubmit,
  isOpen,
  isClose
};
