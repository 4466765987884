// IMPORTS
import { StateStore } from 'viper';

import { MsolPartnerCenterRepository, MsolOffersRepository } from 'service-layer/msol';

// ACTIONS

// FUNCTIONS
const toState = (pcData) => {
  return { loaded: pcData.loaded };
};

const toOffersState = (offerData) => {
  return {
    loaded: offerData.loaded,
    items: offerData.items,
    selected: offerData.selected
  };
};

const toPartnerCenterSelectedState = (pcData) => {
  return {
    partnerCenterSelected: pcData.selected
  };
};

// STREAMS
const stateUpdates = MsolPartnerCenterRepository
  .itemsUpdates
  .map(toState);

const offerListUpdates = Bacon.mergeAll(
  MsolOffersRepository.itemsUpdates.map(toOffersState),
  MsolPartnerCenterRepository.itemsUpdates.map(toPartnerCenterSelectedState)
).scan({}, TP.object.merge);

// SUBSCRIBERS
stateUpdates.onValue(StateStore.publish('msolOfferManagementView'));
offerListUpdates.onValue(StateStore.publish('msolOffers'));

// EXPORTS
