import RepositoryOperationBase from './repository.operation.base';

class ItemsRepositoryOperationBase extends RepositoryOperationBase {
  constructor(config) {
    super(config);

    this.refresh = this.refresh.bind(this);
    this.edit = this.edit.bind(this);
    this.upsert = this.upsert.bind(this);
    this.remove = this.remove.bind(this);
  }

  refresh(currentDs, change) {
    return {
      items: change.data,
      reordered: true,
      selected: false,
      loaded: true
    };
  }

  edit(currentDs, change) {
    const item = currentDs.getItem(change.data.id);
    if (!item) return null;
    const updated = TP.object.merge(item, change.data.edit);
    return {
      items: TP.array.replace(currentDs.getItemIndex(change.data.id), updated, currentDs.getItems()),
      updated: [updated]
    };
  }

  insert(currentDs, change) {
    const added = change.data.item;
    return {
      items: TP.array.insert(-1, added, currentDs.getItems()),
      added: [added]
    };
  }

  upsert(currentDs, change) {
    const item = currentDs.getItem(change.data.id);
    return item
      ? this.edit(currentDs, change)
      : this.insert(currentDs, { data: { id: change.data.id, item: change.data.edit } });
  }

  remove(currentDs, change) {
    const item = currentDs.getItem(change.data.id);
    const items = TP.array.filter((i) => {
      return i.id !== change.data.id;
    }, currentDs.getItems());
    return {
      items,
      removed: [item],
      reordered: true
    };
  }

  clear() {
    return {
      items: [],
      reordered: true,
      selected: false,
      loaded: false
    };
  }

  select(currentDs, change) {
    return {
      selected: change.data.id
    };
  }
}

export default ItemsRepositoryOperationBase;
