// IMPORTS
import { ActionDispatcher } from 'viper';

import {
  MsolOffersController,
  MsolPartnerCenterRepository
} from 'service-layer/msol';

// ACTIONS
const offerSelectAction = ActionDispatcher.register('msolOffers');
const offerSaveCancelAction = ActionDispatcher.register('msolOffer');

// FUNCTIONS
const toPartnerCenterId = (pcData) => {
  return { id: pcData.selected };
};

const toOfferId = (selectAction) => {
  return {
    id: selectAction.id
  };
};

// STREAMS
const loadUpdates = MsolPartnerCenterRepository
  .itemsUpdates
  .skipDuplicates(TP.object.eqProps('selected'))
  .filter(TP.object.prop('selected'))
  .map(toPartnerCenterId);

const clearUpdates = MsolPartnerCenterRepository
  .itemsUpdates
  .skipDuplicates(TP.object.eqProps('selected'))
  .filter(TP.object.not.prop('selected'));

const selectUpdates = offerSelectAction
  .map(toOfferId)
  .merge(offerSaveCancelAction
    .filter(TP.actions.isCancel)
    .map(() => { return { }; }));

// SUBSCRIBERS
loadUpdates.onValue(MsolOffersController.loadOffers);
clearUpdates.onValue(MsolOffersController.clearOffers);
selectUpdates.onValue(MsolOffersController.selectOffer);

// EXPORTS
