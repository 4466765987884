import { FocusStore } from 'viper';

const FocusDecorator = (config, Component) => {
  class Focus extends Component {
    componentDidMount() {
      super.componentDidMount && super.componentDidMount();

      if (!this.getFocusKey()) return;

      this.focusUnsubscribe = FocusStore.currentFocus
        .onValue(this.applyFocus.bind(this));
    }

    shouldComponentUpdate(nextProps, nextState) {
      if (super.shouldComponentUpdate) {
        if (super.shouldComponentUpdate(nextProps, nextState)) {
          return true;
        }
      }

      return this.state.focusState !== nextState.focusState;
    }

    componentDidUpdate(prevProps, prevState) {
      super.componentDidUpdate && super.componentDidUpdate(prevProps, prevState);
      if (this.state.focusState) {
        this.setFocus(this.state.focusState);
      }
    }

    componentWillUnmount() {
      super.componentWillUnmount && super.componentWillUnmount();

      this.focusUnsubscribe && this.focusUnsubscribe();
      this.focusUnsubscribe = null;
    }

    getFocusKey() {
      return super.getFocusKey ? super.getFocusKey() : this.props.focusKey || config.focusKey;
    }

    applyFocus(focusState) {
      if (focusState.focusKey !== this.getFocusKey()) {
        this.setState({ focusState: null });
      } else {
        this.setState({ focusState });
      }
    }
  }

  Focus.displayName = Component.name || Component.displayName;

  return Focus;
};

export default TP.func.curry(FocusDecorator);
