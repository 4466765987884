import ActionPublisher from 'decorators/action-publisher';
import FocusAble from 'decorators/focusable';

import {
  input,
  tokenInput
} from '../styles/token.input.scss';

const MAX_SIZE = 6;

class TokenInput extends React.Component {
  constructor(props) {
    super(props);

    this.inputRefs = TP.array.create(() => {
      return React.createRef();
    }, MAX_SIZE);

    this.onKeyUp = this.onKeyUp.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onKeyUp(evt) {
    if (TP.keys.isEnter(evt)) {
      this.onSubmit(evt);
      return;
    }
    if (evt.target.value.length > 0 || !TP.keys.isBackspace(evt)) return;
    const index = parseInt(evt.target.getAttribute('data-index'), 10);
    const prevIndex = index - 1;
    if (prevIndex >= 0) {
      this.inputRefs[prevIndex].current.focus();
    }
  }

  onSubmit() {
    this.publishValue(Constants.actionTypes.SUBMIT);
  }

  onChange(evt) {
    if (evt.target.value.length !== 1) return;

    const index = parseInt(evt.target.getAttribute('data-index'), 10);
    const nextIndex = index + 1;

    if (nextIndex < MAX_SIZE) {
      this.inputRefs[nextIndex].current.focus();
    }

    this.publishValue(Constants.actionTypes.CHANGE);
  }

  setFocus() {
    if (this.inputRefs[0].current) {
      this.inputRefs[0].current.focus();
      this.clearFocus();
    }
    return true;
  }

  getValueFromDom() {
    let value = '';

    for (let i = 0; i < MAX_SIZE; i += 1) {
      if (this.inputRefs[i]) {
        value += this.inputRefs[i].current.value;
      }
    }

    return value;
  }

  publishValue(type) {
    this.publishAction({
      type,
      field: this.props.field,
      value: this.getValueFromDom()
    });
  }

  renderInputs() {
    const inputs = [];

    for (let i = 0; i < MAX_SIZE; i += 1) {
      inputs.push(
        <input
          key={i}
          onKeyUp={this.onKeyUp}
          onChange={this.onChange}
          className={input}
          ref={this.inputRefs[i]}
          data-index={i}
          type="text"
          maxLength="1"
        />
      );
    }

    return inputs;
  }

  render() {
    return (
      <div className={`${tokenInput} ${this.props.containerClassName || ''}`}>
        {
          this.renderInputs()
        }
      </div>
    );
  }
}

export default TP.func.pipe(
  ActionPublisher({ }),
  FocusAble({ })
)(TokenInput);
