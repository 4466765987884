// IMPORTS
import { Api } from 'viper';

// FUNCTIONS
const listPartnerCenters = (_, cb) => {
  const request = Api.get('/api/service/msol/management/partner-centers');
  Api.addDefaultListeners(request, cb, 'Failed to retrieve partner center connections.');
};

const createPartnerCenter = (partnerCenter, cb) => {
  const request = Api.post('/api/service/msol/management/partner-centers', partnerCenter);
  Api.addDefaultListeners(request, cb, 'Failed to create partner center connection.');
};

const updatePartnerCenter = (partnerCenter, cb) => {
  const request = Api.put(`/api/service/msol/management/partner-centers/${partnerCenter.id}`, partnerCenter);
  Api.addDefaultListeners(request, cb, 'Failed to update partner center connection.');
};

const deletePartnerCenter = (partnerCenter, cb) => {
  const request = Api.del(`/api/service/msol/management/partner-centers/${partnerCenter.id}`);
  Api.addDefaultListeners(request, cb, 'Failed to delete partner center connection.');
};

const getPartnerCenterRegions = (_, cb) => {
  const request = Api.get('/api/service/msol/management/partner-centers/regions');
  Api.addDefaultListeners(request, cb, 'Failed to load partner center regions');
};

const listOffers = (partnerCenter, cb) => {
  const request = Api.get(`/api/service/msol/management/partner-centers/${partnerCenter.id}/offers`);
  Api.addDefaultListeners(request, cb, 'Failed to load offers for partner center.');
};

const saveOffer = (partnerCenter, offer, cb) => {
  const request = Api.put(`/api/service/msol/management/partner-centers/${partnerCenter.id}/offers/${offer.id}`, offer);
  Api.addDefaultListeners(request, cb, 'Failed to save offer in database.');
};

const syncOffers = (partnerCenter, cb) => {
  const request = Api.post(`/api/service/msol/management/partner-centers/${partnerCenter.id}/offers/sync`, {});
  Api.addDefaultListeners(request, cb, 'Failed to sync offers from azure.');
};

// EXPORTS
export default {
  listPartnerCenters,
  createPartnerCenter,
  updatePartnerCenter,
  getPartnerCenterRegions,
  deletePartnerCenter,
  listOffers,
  saveOffer,
  syncOffers
};
