import Paper from '@material-ui/core/Paper';

import StateReader from 'decorators/state-reader';
import Theme from 'decorators/theme';

import Spinner from 'components/spinner';
import Text from 'components/text';

import PartnerCenterConnection from './msol.partner.center.connection';
import PartnerCenterConnections from './msol.partnercenter.connections';

import {
  container,
  spinnerContainer,
  spinnerText
} from '../styles/partner.center.connection.scss';

class MsolPartnerCenter extends React.Component {
  renderView() {
    switch (this.state.currentState.view) {
      case 'connection':
        return (
          <PartnerCenterConnection
            defaultKey="msolPartnerCenterConnection"
          />
        );
      case 'connections':
        return (
          <PartnerCenterConnections
            defaultKey="msolPartnerCenters"
            actionKey="partnerCenterSelect"
          />
        );
      default:
        return (
          <Paper className={spinnerContainer}>
            <Spinner key="loading" color="#0000FF" />
            <Text className={spinnerText} type={Text.NORMAL} text="Partner Center Connections are loading..." />
          </Paper>
        );
    }
  }

  render() {
    return (
      <div className={container}>
        {this.renderView()}
      </div>
    );
  }
}

export default TP.func.pipe(
  StateReader({ defaultState: { view: '' }, stateKey: 'partnerCenterConnectionView' }),
  Theme({})
)(MsolPartnerCenter);
