import QRCode from 'qrcode.react';

import ActionPublisher from 'decorators/action-publisher';
import Focus from 'decorators/focus';
import Form from 'decorators/form';
import StateReader from 'decorators/state-reader';

import Button from 'components/button';
import TokenInput from 'components/token-input';

import {
  mfaInput,
  qrCode,
  registerContainer,
  registerContainerSuccess,
  saveButton
} from '../styles/mfa.register.scss';

class LoginMFA extends React.Component {
  renderError() {
    if (!this.state.currentState.errorMessage) return null;
    return (
      <div style={{ color: 'red' }}>
        {this.state.currentState.errorMessage}
      </div>
    );
  }

  renderContent() {
    if (!this.state.currentState.qrCodeUrl) {
      return (
        <div>Loading registration data...</div>
      );
    }

    return (
      <div>
        <div className={qrCode}>
          <QRCode value={this.state.currentState.qrCodeUrl} />
        </div>
        <div className={mfaInput}>
          {this.renderFormField(
            TokenInput,
            'mfaCode',
            false
          )}
        </div>
        {
          this.renderError()
        }
        <div className={saveButton}>
          <Button
            disabled={this.isProcessing()}
            onPublish={this.onSubmit}
            type="primary"
            variant="contained"
            text="Save"
          />
        </div>
      </div>
    );
  }

  render() {
    if (this.state.currentState.success) {
      return (
        <div className={registerContainerSuccess}>
          Your device has been sucessfully registered.
        </div>
      );
    }
    return (
      <div className={registerContainer}>
        <span>
          Scan the QR Code below and fill in the code from your device to complete registration.
        </span>
        {this.renderContent()}
      </div>
    );
  }
}

export default TP.func.pipe(
  StateReader({ }),
  ActionPublisher({ }),
  Form({}),
  Focus({})
)(LoginMFA);
