import { ObjectRepositoryOperationBase } from 'viper';

class AzureCustomerOperations extends ObjectRepositoryOperationBase {
  constructor(config) {
    super(config);
    this.updateDomain = this.updateDomain.bind(this);
  }

  updateDomain(currentDs, change) {
    let { item } = currentDs.getCurrentData();
    const updated = [];
    const domainIndex = TP.array.findIndex(
      (domain) => {
        return domain.id === change.data.id;
      },
      item.customDomains
    );

    if (domainIndex > -1) {
      const update = {};
      if (change.data.attributes) {
        update.attributes = TP.object.merge(
          item.customDomains[domainIndex].attributes || {},
          change.data.attributes
        );
      } else {
        update.inAzure = change.data.inAzure;
      }

      const updateDomain = TP.object.merge(
        item.customDomains[domainIndex],
        update
      );

      item = TP.object.merge(
        item,
        {
          customDomains: TP.array.replace(domainIndex, updateDomain, item.customDomains)
        }
      );
      updated.push(item);
    }

    return {
      item,
      added: [],
      updated,
      removed: [],
      reordered: false,
      change
    };
  }
}

export default AzureCustomerOperations;
