import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ActionPublisher from 'decorators/action-publisher';

class AtriaExpansionPanel extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, newExpanded) {
    this.publishAction({ expanded: newExpanded });
  }

  render() {
    return (
      <ExpansionPanel expanded={!!this.props.expanded} onChange={this.handleChange}>
        <ExpansionPanelSummary
          expandIcon={this.props.expandIcon || <ExpandMoreIcon />}
          aria-controls="panelContact-content"
          id="panelContact-header"
        >
          <b>{this.props.title}</b>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {this.props.children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default ActionPublisher({}, AtriaExpansionPanel);
