// IMPORTS
import { ActionDispatcher, StateStore } from 'viper';

// ACTIONS
const spotlightSearchAction = ActionDispatcher.register('spotlightSearch');

// FUNCTIONS

// STREAMS
const showHideUpdates = Bacon.mergeAll(
  spotlightSearchAction.filter(TP.actions.isOpen).map(TP.func.always({ open: true })),
  spotlightSearchAction.filter(TP.actions.isClose).map(TP.func.always({ open: false }))
);

// SUBSCRIBERS
showHideUpdates.onValue(StateStore.publish('spotlightSearch'));

// EXPORTS
