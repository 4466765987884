import StateReader from 'decorators/state-reader';
import Theme from 'decorators/theme';

import Button from 'components/button';

import MFARegister from './mfa.register';

import { buttons, registerButton } from '../styles/mfa.settings.scss';

const MFA_ENABLED_TEXT = 'MFA has been enabled for your account, please register below.';
const MFA_REGISTERED_TEXT = 'MFA has been setup on your account. You can opt to reset your registration if you believe your MFA keys have been compromised.'; // eslint-disable-line max-len

class MFASettings extends React.Component {
  renderError() {
    if (!this.state.currentState.errorMessage) return null;
    return (
      <div style={{ color: 'red' }}>
        {this.state.currentState.errorMessage}
      </div>
    );
  }

  renderHeader() {
    return (
      <div>
        {this.state.currentState.mfaRegistered ? MFA_REGISTERED_TEXT : MFA_ENABLED_TEXT}
      </div>
    );
  }

  renderReset() {
    if (!this.state.currentState.mfaRegistered) return null;

    return (
      <Button
        actionKey="mfaSettingsReset"
        disabled={this.state.currentState.processing}
        type="primary"
        variant="contained"
        text="Reset"
      />
    );
  }

  renderStatus() {
    return (
      <div className={buttons}>
        <span className={registerButton}>
          <Button
            actionKey="mfaSettingsRegister"
            disabled={this.state.currentState.processing}
            type="primary"
            variant="contained"
            text="Register"
          />
        </span>
        {this.renderReset()}
      </div>
    );
  }

  render() {
    if (!this.state.currentState.loaded) return <div />;
    if (!this.state.currentState.mfaEnabled) {
      return (
        <div style={{ color: 'red' }}>
          Sorry, the administrator has not enabled MFA for your account.
        </div>
      );
    }

    return (
      <div>
        {this.renderHeader()}
        {
          this.state.currentState.view === 'status'
            ? this.renderStatus()
            : (
              <MFARegister
                actionKey="mfaSettingsRegistration"
                stateKey="mfaSettingsRegistration"
                focusKey="mfaSettingsRegistration"
              />
            )
        }
        {this.renderError()}
      </div>
    );
  }
}

export default TP.func.pipe(
  StateReader({ defaultState: { loaded: false, view: 'status' }, stateKey: 'mfaSettings' }),
  Theme({})
)(MFASettings);
