import Paper from '@material-ui/core/Paper';
import StateReader from 'decorators/state-reader';
import Theme from 'decorators/theme';

import Spinner from 'components/spinner';
import Text from 'components/text';

import PartnerCenterSelection from './msol.offer.partner.center.selection';
import OfferList from './msol.offer.management.list';
import OfferPopup from './msol.offer.management.offer';

import {
  container,
  spinnerContainer,
  spinnerText
} from '../styles/offer.management.scss';

class MsolOfferManagement extends React.Component {
  renderLoading() {
    return (
      <div className={container}>
        <Paper className={spinnerContainer}>
          <Spinner key="loading" color="#0000FF" />
          <Text className={spinnerText} type={Text.NORMAL} text="Loading..." />
        </Paper>
      </div>
    );
  }

  render() {
    if (!this.state.currentState.loaded) {
      return this.renderLoading();
    }

    return (
      <div className={container}>
        <div>
          <Text
            type={Text.HEADING_1}
            text="Offer Management"
            contentId="offerManagementTitle"
          />
        </div>
        <div>
          <Text
            type={Text.HEADING_2}
            text="Manage offers available to customers and users to purchase."
            contentId="offerManagementDescription"
          />
        </div>
        <div>
          <PartnerCenterSelection defaultKey="msolPartnerCenters" />
        </div>
        <div>
          <OfferList defaultKey="msolOffers" />
          <OfferPopup defaultKey="msolOffer" />
        </div>
      </div>
    );
  }
}

export default TP.func.pipe(
  StateReader({ defaultState: { loaded: false }, stateKey: 'msolOfferManagementView' }),
  Theme({})
)(MsolOfferManagement);
