import { ModuleRepository } from 'viper';

import './stores/connector.edit.store';
import './stores/connector.load.store';
import './stores/connector.state.store';
import './stores/connector.domain.store';
import './stores/connector.error.store';
import './stores/connector.new.store';
import './stores/connector.connect.store';
import './stores/connector.expand.store';

import CustomerConnectors from './components/customer.connectors';

ModuleRepository.register(Constants.moduleKeys.SERVICE_AZUREAD_CUSTOMER_SERVICE, {
  component: CustomerConnectors
});
