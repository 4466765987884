import { ModuleRepository } from 'viper';

import './stores/login.form.store';
import './stores/login.mfa.store';
import './stores/login.store';

import Login from './components/login';

ModuleRepository.register(Constants.moduleKeys.LOGIN, {
  component: Login
});
