import Logger from '../loggers/logger';

class Merge {
  constructor(config) {
    this.config = config;
    this.exec = this.exec.bind(this);
  }

  exec(dataSource, change) {
    const { OperationsClass } = this.config;
    if (!OperationsClass) {
      Logger.debug(`OperationsClass not found in ${this.config.name}`);
      change.cb && change.cb({ noOperations: true }, dataSource);
      return dataSource;
    }

    const operations = new OperationsClass({ name: this.config.name });
    const result = operations.exec(dataSource, change);

    if (!result) {
      change.cb && change.cb({ noResult: true }, dataSource);
      return dataSource;
    }

    const updatedDataSource = this.config.toDataSource(dataSource, result, change);
    change.cb && change.cb(null, updatedDataSource);
    return updatedDataSource;
  }
}

export default Merge;
