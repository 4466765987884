import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

class Spinner extends React.Component {
  constructor(props) {
    super();
    if (props.color) {
      this.theme = createMuiTheme({
        typography: {
          useNextVariants: true
        },
        palette: {
          primary: { main: props.color }
        }
      });
    }
  }

  render() {
    const spinner = (
      <CircularProgress />
    );

    if (!this.theme) {
      return spinner;
    }

    return (
      <MuiThemeProvider theme={this.theme}>
        {spinner}
      </MuiThemeProvider>
    );
  }
}

export default Spinner;
