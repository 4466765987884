import {
  all
} from 'rambda';

import notEmpty from './notempty.validator';

export default {
  all,
  notEmpty
};
