class FocusStore {
  constructor() {
    this.incommingFocusUpdates = new Bacon.Bus();
    this.currentFocus = this.incommingFocusUpdates
      .toEventStream()
      .scan({}, this.mergeFocusUpdates)
      .skipDuplicates(); // skip if no updates where applied

    this.setFocus = this.setFocus.bind(this);
    this.blurFocus = this.blurFocus.bind(this);

    if (process.env.NODE_ENV !== 'production') {
      this.currentFocus = this.currentFocus.doLog('[FocusStore]::currentFocus');
    }
  }

  mergeFocusUpdates(current, update) {
    switch (update.type) {
      case 'focus':
        if (current.focusKey === update.focusData.focusKey && current.field === update.focusData.field) {
          return current;
        }

        return TP.object.pick(['focusKey', 'field'], update.focusData);
      case 'blur':
        if (current.focusKey !== update.blurData.focusKey || current.field !== update.blurData.field) {
          return current;
        }

        return {};
      default:
        return current;
    }
  }

  setFocus(focusData) {
    this.incommingFocusUpdates.push({ type: 'focus', focusData });
  }

  blurFocus(blurData) {
    this.incommingFocusUpdates.push({ type: 'blur', blurData });
  }
}

export default new FocusStore();
