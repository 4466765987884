import Logger from '../loggers/logger';

const modules = {};

const register = (key, moduleDef) => {
  if (process.env.NODE_ENV !== 'production') {
    if (modules[key]) {
      Logger.warning(`A module with the key ${key} has already been registerd, this will overwrite the previous registration`);
    }
  }
  modules[key] = moduleDef;
};

const getModule = (key) => {
  return modules[key] || {};
};

export default {
  register,
  getModule
};
