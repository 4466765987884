import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ActionPublisher from 'decorators/action-publisher';
import StateReader from 'decorators/state-reader';

import Button from 'components/button';
import Checkbox from 'components/checkbox';
import TextInput from 'components/text-input';

import {
  offerEditInput
} from '../styles/offer.management.scss';

class MsolManagementOffer extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onSubmit() {
    this.publishAction({ type: Constants.actionTypes.SUBMIT });
  }

  onCancel() {
    this.publishAction({ type: Constants.actionTypes.CANCEL });
  }

  render() {
    return (
      <Dialog open={this.state.currentState.visible} onClose={this.onCancel}>
        <DialogTitle id="form-dialog-title">Edit Offer Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.currentState.name}
          </DialogContentText>
          <TextInput
            defaultKey="msolOfferEdit"
            field="label"
            label="Label in Atria"
            className={offerEditInput}
            value={this.state.currentState.label}
          />
          <TextInput
            defaultKey="msolOfferEdit"
            field="sku"
            label="Sku"
            className={offerEditInput}
            value={this.state.currentState.sku}
          />
          <Checkbox
            defaultKey="msolOfferEdit"
            field="isEnabled"
            contentId="offerEnabled"
            text="Enable offer"
            checked={this.state.currentState.isEnabled}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onPublish={this.onCancel}
            type="primary"
            variant="text"
            text="cancel"
          />
          <Button
            onPublish={this.onSubmit}
            type="primary"
            variant="contained"
            text="Save"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

export default TP.func.pipe(
  StateReader({ defaultState: { visible: false } }),
  ActionPublisher({})
)(MsolManagementOffer);
