// IMPORTS
import { ActionDispatcher } from 'viper';

import { MsolPartnerCenterController } from 'service-layer/msol';

// ACTIONS
const saveAction = ActionDispatcher.register('msolPartnerCenterConnection');
const deleteAction = ActionDispatcher.register('msolPartnerCenterConnectionDelete');

// FUNCTIONS
const isValid = (item) => {
  return item.formValid;
};

const toPartnerCenterConenction = (formData) => {
  return {
    connectionType: formData.values.connectionType,
    label: formData.values.label
  };
};

const validDeletion = deleteAction
  .filter(TP.actions.isSubmit)
  .filter(isValid)
  .map(toPartnerCenterConenction);

// STREAMS
const validSaveUpdates = saveAction
  .filter(TP.actions.isSubmit)
  .filter(isValid)
  .map(toPartnerCenterConenction);

// SUBSCRIBERS
validSaveUpdates.onValue(MsolPartnerCenterController.saveConnection);
validDeletion.onValue(MsolPartnerCenterController.deleteConnection);

// EXPORTS
