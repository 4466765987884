// IMPORTS
import { ActionDispatcher, StateStore } from 'viper';

import {
  AzureCustomerRepository,
  ConnectorsController
} from 'service-layer/connectors';

// ACTIONS
const domainAction = ActionDispatcher.register('customerConnectorsAzureDomain');
const verifyAction = ActionDispatcher.register('customerConnectorsAzureDomainVerify');

// FUNCTIONS
const toDomainState = (connector) => {
  return {
    connectorStatusId: connector.connectorStatusId,
    azureDomain: connector.azureDomain,
    domainAvailable: connector.domainAvailable,
    isVerifying: connector.isVerifying
  };
};

const toAzureDomain = (prefixFieldUpdate) => {
  return {
    azureDomain: `${prefixFieldUpdate.value}.onmicrosoft.com`
  };
};

const toVerifyUpdate = (connector) => {
  return {
    prefix: connector.azureDomain,
    customerId: connector.customerId,
    partnerCenterId: connector.partnerCenterId
  };
};

// STREAMS
const domainUpdates = domainAction
  .map(toAzureDomain);

const stateUpdates = AzureCustomerRepository
  .itemUpdates
  .map(toDomainState);

const verifyUpdates = Bacon.when(
  [AzureCustomerRepository.itemUpdates.toProperty(), verifyAction], toVerifyUpdate
);

const verifyingUpdates = verifyAction
  .map(() => {
    return {
      isVerifying: true
    };
  });

// SUBSCRIBERS
stateUpdates
  .onValue(StateStore.publish('customerConnectorAzureDomains'));

verifyingUpdates.onValue(ConnectorsController.editPrimaryAzureConnector);
domainUpdates.onValue(ConnectorsController.editPrimaryAzureConnector);
verifyUpdates.onValue(ConnectorsController.verifyDomain);

// EXPORTS
