import StateReader from 'decorators/state-reader';

import Select from 'components/select';

import {
  partnerCenterSelect
} from '../styles/offer.management.scss';

class MsolRegionSelect extends React.Component {
  render() {
    return (
      <Select
        defaultKey="msolRegionSelect"
        actionKey="partnerCenterEdit"
        label="Region"
        selectDefaultText="Select Region"
        options={this.state.currentState.items}
        formSelectClassName={partnerCenterSelect}
        field="regionOptionId"
        value={this.props.value}
      />
    );
  }
}

export default StateReader({}, MsolRegionSelect);
