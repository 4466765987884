// IMPORTS
import {
  ActionDispatcher,
  StateStore
} from 'viper';

import {
  MsolPartnerCenterRepository,
  MsolPartnerCenterController
} from 'service-layer/msol';

// ACTIONS
const syncAction = ActionDispatcher.register('warningDialog');
const syncConfirmAction = ActionDispatcher.register('warningDialogConfirm');

const deleteAction = ActionDispatcher.register('deleteDialog');
const deleteConfirmAction = ActionDispatcher.register('deleteDialogConfirm');

// FUNCTIONS
const toVisible = () => { return { visible: true }; };
const toHidden = () => { return { visible: false }; };

const getSelected = (partnerCenterDs) => {
  return partnerCenterDs.getSelected();
};

// STREAMS
const visibleUpdates = syncAction.map(toVisible)
  .merge(syncConfirmAction.map(toHidden));

const syncUpdates = syncConfirmAction
  .filter(TP.actions.isSubmit);

const partnerCenterSyncUpdates = Bacon.when(
  [
    MsolPartnerCenterRepository.itemsUpdates.toProperty(),
    syncUpdates
  ],
  getSelected
);

const visibleDelDialog = deleteAction.map(toVisible)
  .merge(deleteConfirmAction.map(toHidden));

const syncDelete = deleteConfirmAction
  .filter(TP.actions.isSubmit);

const partnerCenterDelete = Bacon.when(
  [
    MsolPartnerCenterRepository.itemsUpdates.toProperty(),
    syncDelete
  ],
  getSelected
);

// SUBSCRIBERS
visibleUpdates.onValue(StateStore.publish('warningDialogConfirm'));
partnerCenterSyncUpdates.onValue(MsolPartnerCenterController.clearSelection);

visibleDelDialog.onValue(StateStore.publish('deleteDialogConfirm'));
partnerCenterDelete.onValue(MsolPartnerCenterController.remove);

// EXPORTS
