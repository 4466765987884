import ItemDataSource from './item.data.source';
import Merge from './merge';
import ObjectRepositoryOperationBase from './object.repository.operation.base';

const toDataSource = (cleaned, result) => {
  const ds = new ItemDataSource();
  ds.loadFromMerge(cleaned, result);
  return ds;
};

class ObjectRepository {
  constructor(config) {
    this.config = config;
    this.merge = new Merge({
      toDataSource,
      name: config.name,
      OperationsClass: config.OperationsClass || ObjectRepositoryOperationBase
    });
    this.incommingUpdates = new Bacon.Bus();

    this.rawUpdates = this.incommingUpdates
      .scan(new ItemDataSource(), this.merge.exec);

    this.itemUpdates = this.rawUpdates
      .map('.item')
      .skipDuplicates();

    this.pushUpdate = TP.func.curry(this.pushUpdate.bind(this));
    this.refresh = this.pushUpdate('refresh');
    this.edit = this.pushUpdate('edit');
  }

  pushUpdate(type, data, cb) {
    this.incommingUpdates.push({ type, data, cb });
  }
}

export default ObjectRepository;
