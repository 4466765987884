import { Logger } from 'viper';

const FormDecorator = (config, Component) => {
  if (process.env.NODE_ENV !== 'production') {
    if (!Component.prototype.publishAction) {
      Logger.warning(`Added FormDecorator to ${Component.displayName || Component.name}. FormDecorator is expecting a publishAction method`);
    }
  }

  const supportedFieldUpdateActionTypes = TP.object.fromKeys(
    true,
    [
      Constants.actionTypes.BLUR,
      Constants.actionTypes.CHANGE,
      Constants.actionTypes.SUBMIT
    ]
  );

  class Form extends Component {
    constructor(props) {
      super(props);

      this.state = TP.object.merge(this.state || {}, this.getDefaultState());
      this.fieldRefs = {};

      this.onSubmit = this.onSubmit.bind(this);
      this.onFieldUpdate = this.onFieldUpdate.bind(this);
      this.setFieldRef = TP.func.curry(this.setFieldRef.bind(this));
      this.setFocus = this.setFocus.bind(this);
    }

    // state reader would otherwhise stop updates from happening
    shouldComponentUpdate(nextProps, nextState) {
      if (super.shouldComponentUpdate) {
        if (super.shouldComponentUpdate(nextProps, nextState)) {
          return true;
        }
      }

      return this.state.formState !== nextState.formState;
    }

    onSubmit() {
      this.onFieldUpdate({ type: Constants.actionTypes.SUBMIT });
    }

    onFieldUpdate(change) {
      if (TP.actions.not.oneOf(supportedFieldUpdateActionTypes, change)) {
        return;
      }

      const isSubmit = TP.actions.isSubmit(change);
      // do not re-submit a form that is being processed
      if (isSubmit && this.state.formState.processing) return;

      const { formState } = this.state;
      const newFormState = TP.object.merge({}, formState);
      const { field } = change;

      // add the new state from the field if it exists
      if (field) {
        newFormState.valid = TP.object.assoc(field, change.valid !== false, formState.valid);
        newFormState.values = TP.object.assoc(field, change.value, formState.values);
        newFormState.dirty = TP.object.assoc(field, true, formState.dirty);

        if (TP.actions.isBlur(change) || isSubmit) {
          newFormState.blurred = TP.object.assoc(field, true, formState.blurred);
        }
      }

      // is the form valid after the state updates have been applied
      const valid = TP.validators.all(TP.func.identity, TP.object.values(newFormState.valid));

      // if we are submitting we need to set some additional props
      if (isSubmit) {
        newFormState.formBlurred = true;

        // only assume we are processing when the form is valid
        // this can be overriden by way of sending state to set this value explicetely
        if (valid) {
          newFormState.processing = true;
        }
      }

      // set the new formState
      this.setState({ formState: newFormState });

      // send an action of the same type out. Form here largely functions as a proxy.
      this.publishAction(
        {
          type: change.type,
          values: formState.values,
          dirty: formState.dirty,
          valid: formState.valid,
          formValid: valid,
          field
        }
      );
    }

    getDefaultState() {
      const valid = (this.getDefaultValidState && this.getDefaultValidState()) || { };
      return {
        formState: {
          formBlurred: false,
          isProcessing: false,
          blurred: { },
          dirty: { },
          valid,
          values: { }
        }
      };
    }

    setFieldRef(field, node) {
      this.fieldRefs[field] = node;
    }

    setFocus(update) {
      if (this.fieldRefs[update.field]) {
        this.fieldRefs[update.field].setFocus();
      } else if (this.fieldRefs[update.id]) {
        this.fieldRefs.setFocus();
      }
    }

    // allow to listen in on state updates
    applyState(stateUpdate) {
      super.applyState && super.applyState(stateUpdate);
      if (TP.object.has('processing', stateUpdate)) {
        // eslint-disable-next-line react/no-access-state-in-setstate
        const newFormState = TP.object.assoc('processing', stateUpdate.processing, this.state.formState);
        this.setState({ formState: newFormState });
      }
    }

    isProcessing() {
      return this.state.formState.processing;
    }

    showFieldError(field) {
      const { formState } = this.state;
      return formState.valid[field] === false && (formState.blurred[field] || formState.formSubmitted);
    }

    renderFormField(FieldComponent, field, required, validator, props) {
      return (
        <FieldComponent
          {...props}
          onPublish={this.onFieldUpdate}
          id={`${this.props.id}_${field}`}
          focusKey={this.getFocusKey()}
          field={field}
          ref={this.setFieldRef(field)}
          error={this.showFieldError(field)}
          validator={validator}
          required={required}
        />
      );
    }

    render() {
      return (
        <form
          onSubmit={this.onSubmit}
        >
          {super.render()}
        </form>
      );
    }
  }

  Form.displayName = Component.displayName || Component.name;

  return Form;
};

export default TP.func.curry(FormDecorator);
