// IMPORTS
import { ActionDispatcher, StateStore } from 'viper';

import { SpotlightSearchController } from 'service-layer/search';

// ACTIONS
const spotlightSearchTextAction = ActionDispatcher.register('spotlightSearchText');

// FUNCTIONS
const toSearchRequest = (searchString) => {
  return {
    searchString
  };
};

const toSearchResult = (response) => {
  const customers = [];
  const users = [];
  for (let i = 0; i < response.data.length; i += 1) {
    const { type } = response.data[i];
    if (type === 'C') {
      customers.push(response.data[i]);
    }
    if (type === 'U') {
      users.push(response.data[i]);
    }
  }
  return {
    customers,
    users,
    totalCount: response.data.length
  };
};

// STREAMS
const searchTextUpdates = spotlightSearchTextAction
  .map(TP.object.prop('value'))
  .skipDuplicates()
  .debounce(300)
  .map(toSearchRequest)
  .flatMapLatest(SpotlightSearchController.getSearchResults);

const searchResultUpdates = searchTextUpdates
  .skipErrors()
  .map(toSearchResult);

// SUBSCRIBERS
searchResultUpdates.onValue(StateStore.publish('spotlightSearchResults'));
searchTextUpdates.skipErrors()
  .map(toSearchResult)
  .onValue();

// EXPORTS
