import { StateStore } from 'viper';

const StateReaderDecorator = TP.func.curry((config, Component) => {
  class StateReader extends Component {
    constructor(props) {
      super(props);
      const state = ({ currentState: config.defaultState || { } });
      this.state = this.state ? TP.object.merge(this.state, state) : state;

      this.applyState = this.applyState.bind(this);
    }

    componentDidMount() {
      if (super.componentDidMount) {
        super.componentDidMount();
      }
      this.stateReaderUnsubscribe = StateStore.currentState
        .map(TP.object.propOr({}, this.findStateKey()))
        .skipDuplicates((prev, next) => { return prev.version === next.version; })
        .onValue(this.applyState);
    }

    shouldComponentUpdate(nextProps, nextState) {
      if (super.shouldComponentUpdate) {
        if (super.shouldComponentUpdate(nextProps, nextState)) {
          return true;
        }
      }

      return nextProps.children
        || this.state.currentState.version !== nextState.currentState.version
        || TP.logic.shallowEquals(this.props.equals, nextProps);
    }

    componentWillUnmount() {
      if (super.componentWillUnmount) {
        super.componentWillUnmount();
      }
      if (this.stateReaderUnsubscribe) {
        this.stateReaderUnsubscribe();
        this.stateReaderUnsubscribe = null;
      }
    }

    findStateKey() {
      return this.props.stateKey || this.props.defaultKey || config.stateKey;
    }

    applyState(stateUpdate) {
      this.setState({ currentState: stateUpdate });
    }
  }

  StateReader.displayName = Component.displayName || Component.name;

  return StateReader;
});

export default StateReaderDecorator;
