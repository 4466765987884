// IMPORTS
import { ConnectorsController } from 'service-layer/connectors';

// ACTIONS

// FUNCTIONS
const toError = (update) => {
  return {
    errorMessage: (update.err.data && update.err.data.friendlyMessage) || update.err.friendlyMessage,
    loaded: true,
    inProgress: false
  };
};

const toLoadError = (update) => {
  return {
    errorMessage: update.err.friendlyMessage || 'Failed to load connector',
    loaded: false
  };
};

const toConnectError = (update) => {
  return {
    errorMessage: (update.err.data && update.err.data.friendlyMessage) || update.err.friendlyMessage || 'Failed connect customer with azure tenant.',
    loaded: false,
    inProgress: false
  };
};

// STREAMS
const errorUpdates = Bacon.mergeAll(
  ConnectorsController.events.provisionSaveErrors.map(toError),
  ConnectorsController.events.loadErrors.map(toLoadError),
  ConnectorsController.events.connectErrors.map(toConnectError)
);

// SUBSCRIBERS
errorUpdates.onValue(ConnectorsController.editPrimaryAzureConnector);

// EXPORTS
