// IMPORTS
import { ActionDispatcher, FocusStore, StateStore } from 'viper';

import { AccountController } from 'service-layer/account';

import { LoginUpdates } from './login.form.store';
import { MFASuccessUpdates } from './login.mfa.store';

// ACTIONS
const loginChangePasswordAction = ActionDispatcher.register('loginChangePassword');

// FUNCTIONS
const toChangePasswordData = (formData) => {
  return {
    data: {
      oldPassword: formData.values.currentPassword,
      newPassword: formData.values.newPassword
    },
    valid: formData.values.newPassword === formData.values.confirmNewPassword
  };
};

const toChangePasswordError = (loginResult) => {
  return {
    errorMessage: loginResult.friendlyMessage,
    processing: false
  };
};

const toPasswordsMismatch = () => {
  return {
    errorMessage: 'New Password\'s don\'t match',
    processing: false
  };
};

// STREAMS
const changePasswordUpdates = loginChangePasswordAction
  .filter(TP.actions.isSubmit)
  .filter(TP.object.prop('formValid'))
  .map(toChangePasswordData);

const changePasswordRequestUpdates = changePasswordUpdates
  .filter(TP.object.prop('valid'))
  .map(TP.object.prop('data'))
  .flatMap(AccountController.changePassword);

const changePasswordInvalidUpdates = changePasswordUpdates
  .filter(TP.object.not.prop('valid'))
  .map(toPasswordsMismatch);

const changePasswordErrorUpdates = changePasswordRequestUpdates
  .errors()
  .mapError(toChangePasswordError);

const changePasswordCompleteUpdates = changePasswordRequestUpdates
  .skipErrors()
  .map(TP.object.prop('data'));

const formLoadUpdates = Bacon.mergeAll(
  LoginUpdates.filter(TP.object.not.propEq('signInStatus', 'RequiresVerification')),
  MFASuccessUpdates
)
  .filter(TP.object.propEq('accountStatus', 'PasswordExpired'));

const focusUpdates = formLoadUpdates
  .merge(changePasswordErrorUpdates)
  .merge(changePasswordInvalidUpdates)
  .map(TP.func.always({ focusKey: 'loginChangePassword', field: 'currentPassword' }));

// SUBSCRIBERS
changePasswordErrorUpdates.merge(changePasswordInvalidUpdates).onValue(StateStore.publish('loginChangePassword'));
focusUpdates.onValue(FocusStore.setFocus);

// EXPORTS
export const ChangePasswordCompleteUpdates = changePasswordCompleteUpdates;
