class DataSource {
  constructor(config) {
    this.config = TP.object.merge(
      {
        itemsKey: 'items',
        itemsDefault: () => { return []; },
        hasKeyIndex: true
      },
      config
    );
    this.initializeDS();
  }

  initializeDS(items, idIndex, added, updated, removed, reordered, change) {
    this.dsData = {
      [this.config.itemsKey]: items || this.config.itemsDefault(),
      idIndex: idIndex || {},
      added: added || [],
      updated: updated || [],
      removed: removed || [],
      reordered: !!reordered,
      change: change || {}
    };

    this.selected = this.dsData.selected;
    this.loaded = this.dsData.loaded;
    this[this.config.itemsKey] = this.dsData[this.config.itemsKey];
  }

  loadFromMerge(previousDataSource, newDsData, change) {
    this.previousDsData = previousDataSource.getCurrentData();
    const dsData = TP.object.merge(this.previousDsData, newDsData);
    dsData.created = newDsData.created || [];
    dsData.updated = newDsData.updated || [];
    dsData.deleted = newDsData.deleted || [];
    dsData.reordered = !!newDsData.reordered;
    dsData.change = change;
    dsData[this.config.itemsKey] = newDsData[this.config.itemsKey] || this.previousDsData[this.config.itemsKey] || this.config.itemsDefault();

    if (this.config.hasKeyIndex) {
      let { idIndex } = this.previousDsData;
      if (dsData.reordered || dsData.items.length !== this.previousDsData.items.length || !idIndex) {
        idIndex = TP.array.reduce(
          (newIdIndex, item, index) => {
            newIdIndex[item.id] = index; // eslint-disable-line no-param-reassign
            return newIdIndex;
          },
          {},
          dsData.items
        );
      }

      dsData.idIndex = idIndex;
    }
    this.dsData = dsData;
    this.selected = dsData.selected;
    this.loaded = dsData.loaded;
    this[this.config.itemsKey] = dsData[this.config.itemsKey];
  }

  hasPrevious() {
    return !!this.previousDsData;
  }

  getPreviousData() {
    return this.previousDsData || {};
  }

  getCurrentData() {
    return this.dsData;
  }

  isItemChange() {
    return !this.reordered && (this.added.length || this.updated.length || this.removed.length);
  }
}

export default DataSource;
