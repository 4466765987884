import QRCode from 'qrcode.react';

import ActionPublisher from 'decorators/action-publisher';
import Focus from 'decorators/focus';
import Form from 'decorators/form';
import StateReader from 'decorators/state-reader';

import Button from 'components/button';
import TokenInput from 'components/token-input';

import {
  footer,
  formContainer,
  heading,
  mfaInput,
  qrCode
} from '../styles/login.form.scss';

class LoginMFA extends React.Component {
  renderError() {
    if (!this.state.currentState.errorMessage) return null;
    return (
      <div style={{ color: 'red' }}>
        {this.state.currentState.errorMessage}
      </div>
    );
  }

  renderHeaderText() {
    if (this.state.currentState.register) {
      return 'Your account has been setup with two factor authentication. '
        + 'Scan the QR Code below and fill in the code from your device to complete registration.';
    }
    return (
      <span>
        Please enter your two factor token in the box below. If you don&#39;t have one, please contact your administrator.
      </span>
    );
  }

  renderRegistration() {
    return (
      <div className={qrCode}>
        <QRCode value={this.state.currentState.qrCodeUrl} />
      </div>
    );
  }

  renderContent() {
    if (this.state.currentState.register && !this.state.currentState.qrCodeUrl) {
      return (
        <div>Loading registration data...</div>
      );
    }

    return (
      <div className={formContainer}>
        {this.state.currentState.register && this.renderRegistration()}
        <div className={mfaInput}>
          {this.renderFormField(
            TokenInput,
            'mfaCode',
            false
          )}
        </div>
        {
          this.renderError()
        }
        <div className={footer}>
          <Button
            disabled={this.isProcessing()}
            onPublish={this.onSubmit}
            type="primary"
            variant="contained"
            text={this.state.currentState.register ? 'Register' : 'Verify'}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <h2
          className={heading}
        >
          Sign In Code
        </h2>
        <span>
          {this.renderHeaderText()}
        </span>
        {this.renderContent()}
      </div>
    );
  }
}

export default TP.func.pipe(
  StateReader({ }),
  ActionPublisher({ actionKey: 'loginMFAVerify' }),
  Form({}),
  Focus({})
)(LoginMFA);
