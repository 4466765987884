import { ModuleRepository } from 'viper';

import './stores/mfa.settings.store';
import './stores/mfa.register.store';

import MFASettings from './components/mfa.settings';

ModuleRepository.register(Constants.moduleKeys.MFA_SETTINGS, {
  component: MFASettings
});
