// IMPORTS
import { ActionDispatcher, StateStore } from 'viper';

import {
  MsolPartnerCenterController,
  MsolPartnerCenterRepository
} from 'service-layer/msol';

// ACTIONS
const partnerEditAction = ActionDispatcher.register('partnerCenterEdit');
const saveAction = ActionDispatcher.register('partnerCenterSave');
const newAction = ActionDispatcher.register('msolPartnerCenterNew');

// FUNCTIONS
const toNewState = () => {
  return {
    id: '',
    isNew: true,
    label: '',
    tier: 'Tier1',
    regionOptionId: 0,
    partnerId: '',
    applicationId: '',
    userId: '',
    clientSecret: '',
    refreshToken: '',
    exchangeRefreshToken: '',
    connectorsCount: 0,
    partnerUserName: '',
    tenantTestUserName: ''
  };
};

const toEditState = (update) => {
  const partnerCenter = update.getSelected();
  if (!partnerCenter) {
    return toNewState();
  }
  return TP.object.assoc('isNew', false, partnerCenter);
};

// STREAMS
const editFromSelectUpdates = MsolPartnerCenterRepository
  .itemsUpdates
  .map(toEditState);

const changeUpdates = partnerEditAction.map(TP.object.fromFormField);

const editUpdates = Bacon.mergeAll(
  editFromSelectUpdates,
  changeUpdates,
  newAction.map(toNewState)
)
  .scan({}, TP.object.merge);

const saveUpdates = Bacon.when(
  [editUpdates.toProperty(), saveAction], TP.func.identity
);


// SUBSCRIBERS
editUpdates.onValue(StateStore.publish('msolPartnerCenterConnection'));
saveUpdates.onValue(MsolPartnerCenterController.save);

// EXPORTS
