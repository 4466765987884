import ExpansionPanel from 'components/expansion-panel';

import Text from 'components/text';
import Checkbox from 'components/checkbox';
import TextInput from 'components/text-input';

import {
  labelColumn,
  valueColumn,
  textInput,
  row
} from '../styles/connector.azure.scss';

class AzureConnectorTerms extends React.Component {
  renderTCForm() {
    if (!this.props.tcAccepted) return null;

    return (
      <div>
        <div className={row}>
          <Text
            type={Text.FORM_LABEL_BOLD}
            domComponent="div"
            className={labelColumn}
            text="First Name"
            contentId="customerFirstName"
          />
          <div className={valueColumn}>
            <TextInput
              defaultKey="customerConnectorAzureTCDetails"
              field="tcAcceptedByFirstName"
              fullWidth
              value={this.props.tcAcceptedByFirstName}
              className={textInput}
            />
          </div>
        </div>
        <div className={row}>
          <Text
            type={Text.FORM_LABEL_BOLD}
            domComponent="div"
            className={labelColumn}
            text="Last Name"
            contentId="customerLastName"
          />
          <div className={valueColumn}>
            <TextInput
              defaultKey="customerConnectorAzureTCDetails"
              field="tcAcceptedByLastName"
              fullWidth
              value={this.props.tcAcceptedByLastName}
              className={textInput}
            />
          </div>
        </div>
        <div className={row}>
          <Text
            type={Text.FORM_LABEL_BOLD}
            domComponent="div"
            className={labelColumn}
            text="Customer Email"
            contentId="customerEmail"
          />
          <div className={valueColumn}>
            <TextInput
              defaultKey="customerConnectorAzureTCDetails"
              field="tcAcceptedByEmail"
              fullWidth
              value={this.props.tcAcceptedByEmail}
              className={textInput}
            />
          </div>
        </div>
        <div className={row}>
          <Text
            type={Text.FORM_LABEL_BOLD}
            domComponent="div"
            className={labelColumn}
            text="Phone Number"
            contentId="customerPhoneNumber"
          />
          <div className={valueColumn}>
            <TextInput
              defaultKey="customerConnectorAzureTCDetails"
              field="tcAcceptedByPhoneNumber"
              fullWidth
              value={this.props.tcAcceptedByPhoneNumber}
              className={textInput}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <ExpansionPanel
        expanded={this.props.expanded}
        title="Terms and Conditions"
        defaultKey="connectorExpand"
        id="termsExpanded"
      >
        <div style={{ width: '100%' }}>
          <Checkbox
            defaultKey="customerConnectorsAzureTCAccepted"
            contentId="tancAcceptedByCustomer"
            text="Accepted By Customer"
            checked={this.props.tcAccepted}
            disabled={this.props.connectorStatusId === 3 && this.props.tcAccepted}
          />
          {this.renderTCForm()}
        </div>
      </ExpansionPanel>
    );
  }
}

export default AzureConnectorTerms;
