import { FocusStore } from 'viper';

const FocusableDecorator = (config, Component) => {
  class Focusable extends Component {
    constructor(props) {
      super(props);

      this.focusableRef = React.createRef();
      this.onBlur = this.onBlur.bind(this);
    }

    onBlur(e) {
      super.onBlur && super.onBlur(e);
      this.clearFocus();
    }

    setFocus() {
      if (super.setFocus && super.setFocus()) return;
      if (this.focusableRef.current) {
        this.clearFocus();
        this.focusableRef.current.focus();
      }
    }

    clearFocus() {
      if (this.props.focusKey) {
        FocusStore.blurFocus({ focusKey: this.props.focusKey, field: this.props.field });
      }
    }
  }

  Focusable.displayName = Component.name || Component.displayName;

  return Focusable;
};

export default TP.func.curry(FocusableDecorator);
