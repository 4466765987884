import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ActionPublisher from 'decorators/action-publisher';
import StateReader from 'decorators/state-reader';

import Button from 'components/button';
import Spinner from 'components/spinner';
import Text from 'components/text';

import MsolOfferManagementSync from './msol.offer.management.sync';

import {
  container,
  listHeader,
  spinnerContainer,
  spinnerText
} from '../styles/offer.management.scss';

class MsolOfferManagementList extends React.Component {
  constructor(props) {
    super(props);
    this.selectOffer = this.selectOffer.bind(this);
  }

  selectOffer(evt) {
    const id = evt.target.getAttribute('data-id') || evt.target.parentElement.getAttribute('data-id');
    this.publishAction({ id });
  }

  renderLoading() {
    return (
      <div className={container}>
        <Paper className={spinnerContainer}>
          <Spinner key="loading" color="#0000FF" />
          <Text className={spinnerText} type={Text.NORMAL} text="Loading offers..." />
        </Paper>
      </div>
    );
  }

  renderOffers() {
    return TP.array.map(
      (offer) => {
        return (
          <TableRow key={offer.id} data-id={offer.id}>
            <TableCell component="th" scope="row">
              {offer.name}
            </TableCell>
            <TableCell align="right">{offer.isTrial && 'Y'}</TableCell>
            <TableCell align="right">{offer.isAddon && 'Y'}</TableCell>
          </TableRow>
        );
      },
      this.state.currentState.items
    );
  }

  render() {
    if (!this.state.currentState.partnerCenterSelected) return null;
    if (!this.state.currentState.loaded) {
      return this.renderLoading();
    }

    return (
      <div>
        <div className={listHeader}>
          <Button
            defaultKey="msolOfferSync"
            type="primary"
            variant="text"
            text="Sync Offers from Partner Center"
            contentId="sync"
          />
          <MsolOfferManagementSync defaultKey="msolOfferSyncConfirm" />
        </div>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name in Partnercenter</TableCell>
                <TableCell align="right">Is Trial</TableCell>
                <TableCell align="right">Is Addon</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.renderOffers()}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

export default TP.func.pipe(
  StateReader({ defaultState: { loaded: false } }),
  ActionPublisher({})
)(MsolOfferManagementList);
