import Text from 'components/text';
import TextInput from 'components/text-input';
import Select from 'components/select';

import ExpansionPanel from 'components/expansion-panel';

import {
  labelColumn,
  valueColumn,
  selectFullWidth,
  textInput,
  row
} from '../styles/connector.azure.scss';

class AzureConnectorCustomer extends React.Component {
  componentDidMount() {
    VB.resizeIframe();
  }

  componentDidUpdate() {
    VB.resizeIframe();
  }

  render() {
    return (
      <ExpansionPanel
        expanded={this.props.expanded}
        title="Customer Contact Details"
        defaultKey="connectorExpand"
        id="customerExpanded"
      >
        <div>
          <div className={row}>
            <Text
              type={Text.FORM_LABEL_BOLD}
              domComponent="div"
              className={labelColumn}
              contentId="customerConnectorAddress"
              text="First Name"
            />
            <div className={valueColumn}>
              <TextInput
                className={textInput}
                defaultKey="customerConnectorAddress"
                contentId="customerFirstName"
                field="firstName"
                fullWidth
                value={this.props.firstName}
              />
            </div>
          </div>
          <div className={row}>
            <Text
              type={Text.FORM_LABEL_BOLD}
              domComponent="div"
              className={labelColumn}
              contentId="customerConnectorAddress"
              text="Last Name"
            />
            <div className={valueColumn}>
              <TextInput
                className={textInput}
                defaultKey="customerConnectorAddress"
                contentId="customerFirstName"
                field="lastName"
                fullWidth
                value={this.props.lastName}
              />
            </div>
          </div>
          <div className={row}>
            <Text
              type={Text.FORM_LABEL_BOLD}
              domComponent="div"
              className={labelColumn}
              contentId="customerConnectorAddress"
              text="Email"
            />
            <div className={valueColumn}>
              <TextInput
                className={textInput}
                defaultKey="customerConnectorAddress"
                contentId="customerFirstName"
                field="email"
                fullWidth
                value={this.props.email}
              />
            </div>
          </div>
          <div className={row}>
            <Text
              type={Text.FORM_LABEL_BOLD}
              domComponent="div"
              className={labelColumn}
              contentId="customerConnectorAddress"
              text="Address"
            />
            <div className={valueColumn}>
              <TextInput
                className={textInput}
                defaultKey="customerConnectorAddress"
                contentId="customerFirstName"
                field="address"
                fullWidth
                value={this.props.address}
              />
            </div>
          </div>
          <div className={row}>
            <Text
              type={Text.FORM_LABEL_BOLD}
              domComponent="div"
              className={labelColumn}
              contentId="customerConnectorAddress"
              text="City"
            />
            <div className={valueColumn}>
              <TextInput
                className={textInput}
                defaultKey="customerConnectorAddress"
                contentId="customerFirstName"
                field="city"
                fullWidth
                value={this.props.city}
              />
            </div>
          </div>
          <div className={row}>
            <Text
              type={Text.FORM_LABEL_BOLD}
              domComponent="div"
              className={labelColumn}
              contentId="customerConnectorAddress"
              text="State"
            />
            <div className={valueColumn}>
              <TextInput
                className={textInput}
                defaultKey="customerConnectorAddress"
                contentId="customerFirstName"
                field="state"
                fullWidth
                value={this.props.state}
              />
            </div>
          </div>
          <div className={row}>
            <Text
              type={Text.FORM_LABEL_BOLD}
              domComponent="div"
              className={labelColumn}
              contentId="customerConnectorCountry"
              text="Country"
            />
            <div className={valueColumn}>
              <Select
                defaultKey="customerConnectorAddress"
                selectDefaultText="Select Country..."
                formSelectClassName={selectFullWidth}
                options={this.props.countryOptions}
                field="countryCode"
                value={this.props.countryCode}
              />
            </div>
          </div>
          <div className={row}>
            <Text
              type={Text.FORM_LABEL_BOLD}
              domComponent="div"
              className={labelColumn}
              contentId="customerConnectorAddress"
              text="Postal Code"
            />
            <div className={valueColumn}>
              <TextInput
                className={textInput}
                defaultKey="customerConnectorAddress"
                contentId="customerFirstName"
                field="postalCode"
                fullWidth
                value={this.props.postalCode}
              />
            </div>
          </div>
          <div className={row}>
            <Text
              type={Text.FORM_LABEL_BOLD}
              domComponent="div"
              className={labelColumn}
              contentId="customerConnectorAddress"
              text="Phone Number"
            />
            <div className={valueColumn}>
              <TextInput
                className={textInput}
                defaultKey="customerConnectorAddress"
                contentId="customerFirstName"
                field="phoneNumber"
                fullWidth
                value={this.props.phoneNumber}
              />
            </div>
          </div>
        </div>
      </ExpansionPanel>
    );
  }
}

export default AzureConnectorCustomer;
