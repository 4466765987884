import ActionPublisher from 'decorators/action-publisher';

import { default as MUISelect } from '@material-ui/core/Select'; // eslint-disable-line import/no-named-default
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.publishValue(e, Constants.actionTypes.CHANGE);
  }

  publishValue(e, type) {
    const data = {
      field: this.props.field,
      type,
      value: e.target.value
    };

    if (this.props.validator) {
      data.valid = this.props.validator(data);
    }

    this.publishAction(data);
  }

  renderItems() {
    return TP.array.map(
      (option) => {
        return (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        );
      },
      this.props.options || []
    );
  }

  renderPlaceHolder() {
    if (this.props.value) return null;
    return (
      <MenuItem value="">
        <em>{this.props.selectDefaultText}</em>
      </MenuItem>
    );
  }

  render() {
    return (
      <div>
        <FormControl className={this.props.formSelectClassName}>
          <InputLabel shrink={this.props.value !== '​'} htmlFor={`${this.props.label}-select`}>
            {this.props.label}
          </InputLabel>
          <MUISelect
            autoWidth={this.props.autoWidth}
            value={this.props.value || ''}
            onChange={this.handleChange}
            name={`${this.props.label}`}
            displayEmpty
            input={<Input name={`${this.props.label}`} id={`${this.props.label}-select`} />}
          >
            {
              this.renderPlaceHolder()
            }
            {
              this.renderItems()
            }
          </MUISelect>
        </FormControl>
      </div>
    );
  }
}

Select.defaultProps = {
  type: 'select'
};

export default ActionPublisher({}, Select);
