// IMPORTS
import { ActionDispatcher, FocusStore, StateStore } from 'viper';
import { AccountController } from 'service-layer/account';

// ACTIONS
const loginFormAction = ActionDispatcher.register('loginForm');
const forgotPasswordAction = ActionDispatcher.register('loginFormForgotPassword');
const moduleloginRenderedAction = ActionDispatcher.register('moduleloginRendered');
const loginAADAction = ActionDispatcher.register('loginAzureAD');

// FUNCTIONS
const toLoginData = (formData) => {
  return {
    username: formData.values.username,
    password: formData.values.password
  };
};

const toLoginFailure = (loginResult) => {
  return {
    loginFailedMessage: loginResult.friendlyMessage,
    processing: false
  };
};

const toShowResetUpdate = ({ showResetPassword }) => {
  return {
    showResetPassword
  };
};

const toShowAADButton = ({ showAzureADButton }) => {
  return {
    showAzureADButton
  };
};

const toResetUpdate = ({ values }) => {
  return {
    username: values.username
  };
};

const toDisabledLoginAADButton = () => {
  return {
    processLoginAAD: true
  };
};

const toEnabledLoginAADButton = () => {
  return {
    processLoginAAD: false
  };
};


const navigateReset = ({ username }) => {
  window.top.location.href = `/ResetPassword?username=${username || ''}`;
};

const toLoginTextUpdate = ({ loginPageText }) => {
  return {
    loginPageText,
    showLoginText: loginPageText
  };
};

// STREAMS
const loginRequestUpdates = loginFormAction
  .filter(TP.actions.isSubmit)
  .filter(TP.object.prop('formValid'))
  .map(toLoginData)
  .flatMap(AccountController.login);

const loginAADUpdates = loginAADAction
  .flatMap(AccountController.loginAAD);

const loginErrorUpdates = loginRequestUpdates
  .errors()
  .merge(loginAADUpdates.errors())
  .mapError(toLoginFailure);

const loginAADButtonUpdates = loginAADAction
  .map(toDisabledLoginAADButton)
  .merge(loginAADUpdates.errors().mapError(toEnabledLoginAADButton));

const loginUpdates = loginRequestUpdates.skipErrors()
  .map(TP.object.prop('data'))
  .merge(loginAADUpdates.skipErrors().map(TP.object.prop('data')));

const focusUpdates = moduleloginRenderedAction
  .merge(loginErrorUpdates)
  .map(TP.func.always({ focusKey: 'loginForm', field: 'username' }));

const showResetUpdates = moduleloginRenderedAction.map(toShowResetUpdate);
const showAADButton = moduleloginRenderedAction.map(toShowAADButton);

const loginTextUpdates = moduleloginRenderedAction.map(toLoginTextUpdate);

const resetUpdates = Bacon.when(
  [loginFormAction.toProperty({}), forgotPasswordAction], toResetUpdate
);

const loginStateUpdates = Bacon.mergeAll(
  loginErrorUpdates,
  showResetUpdates,
  loginTextUpdates,
  loginAADButtonUpdates,
  showAADButton
).scan({}, TP.object.merge)
  .filter(TP.object.not.isEmpty);


// SUBSCRIBERS
loginStateUpdates.onValue(StateStore.publish('loginForm'));
focusUpdates.onValue(FocusStore.setFocus);
resetUpdates.onValue(navigateReset);

// EXPORTS
export const LoginUpdates = loginUpdates;
