import StateReader from 'decorators/state-reader';
import Theme from 'decorators/theme';

import Cookies from 'universal-cookie';
import ChangePassword from './change.password';
import LoginForm from './login.form';
import LoginMFA from './login.mfa';

import {
  background,
  formPanel,
  header,
  logo
} from '../styles/login.scss';

class Login extends React.Component {
  setTimeZoneCookie() {
    const date = new Date();
    const timeZone = date.getTimezoneOffset() / 60 * (-1);
    const cookies = new Cookies();
    cookies.set('timezone', timeZone, { path: '/', secure: true });
  }

  renderView() {
    this.setTimeZoneCookie();
    switch (this.state.currentState.view) {
      case 'mfa':
        return <LoginMFA stateKey="loginMFA" actionKey="loginMFA" focusKey="loginMFA" />;
      case 'changePassword':
        return <ChangePassword stateKey="loginChangePassword" actionKey="loginChangePassword" focusKe="loginChangePassword" />;
      default:
        return <LoginForm stateKey="loginForm" actionKey="loginForm" focusKey="loginForm" />;
    }
  }

  render() {
    return (
      <div className={background}>
        <div className={header}>
          <div className={logo} />
        </div>
        <div className={formPanel}>
          {this.renderView()}
        </div>
      </div>
    );
  }
}

export default TP.func.pipe(
  StateReader({ defaultState: { view: 'login' }, stateKey: 'loginView' }),
  Theme({})
)(Login);
