import ReactDom from 'react-dom';

import {
  ActionDispatcher,
  Logger,
  ModuleRepository
} from 'viper';

const publishAction = (key, event) => {
  ActionDispatcher.publish(key, event);
};

const renderModule = (key, domId, data) => {
  const module = ModuleRepository.getModule(key);
  if (process.env.NODE_ENV !== 'production') {
    if (!module) {
      Logger.error(`Failed to find module with key: ${key}`);
    }
    if (module && !module.component) {
      Logger.error(`Module with key: ${key} does not define a component`);
    }
  }

  if (!module || !module.component) return;

  const element = document.getElementById(domId);
  if (!element) {
    Logger.error(`Failed to find dom element by id: ${domId}`);
  }
  const Control = module.component;
  ReactDom.render(<Control />, element); // eslint-disable-line react/jsx-filename-extension
  ActionDispatcher.publish(`module${key}Rendered`, data || {});
};

const readFromInput = (domId) => {
  const obj = document.getElementById(domId);
  if (!obj) {
    Logger.error(`Failed to find dom element by id: ${domId}`);
    return null;
  }
  const { value } = obj;

  return value ? JSON.parse(value) : null;
};

const writeToInput = (domId, data) => {
  const obj = document.getElementById(domId);
  if (!obj) {
    return;
  }

  obj.value = JSON.stringify(data);
};

const resizeIframe = () => {
  setTimeout(() => {
    window.SetiFrame(window.iframeItemId, 60);
    window.SetiFrame(window.iframeItemId, 5);
  }, 0);
};

const clickProvision = () => {
  const nodes = document.getElementsByTagName('a');
  nodes.forEach((n) => {
    n.id && n.id.endsWith('lnkProvision') && n.click();
  });
};

export default {
  clickProvision,
  publishAction,
  readFromInput,
  renderModule,
  writeToInput,
  resizeIframe
};
