
import PropTypes from 'prop-types';

class CapsLockIndicator extends React.Component {
  static propTypes = { children: PropTypes.func.isRequired };

  constructor(props) {
    super(props);
    this.state = { isCapsLockActive: false };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.wasCapsLockActivated);
    document.addEventListener('keyup', this.wasCapsLockDeactivated);

    document.addEventListener('mousedown', this.wasCapsLockActivated);
    document.addEventListener('mouseup', this.wasCapsLockDeactivated);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.wasCapsLockActivated);
    document.removeEventListener('keyup', this.wasCapsLockDeactivated);

    document.removeEventListener('mousedown', this.wasCapsLockActivated);
    document.removeEventListener('mouseup', this.wasCapsLockDeactivated);
  }

  wasCapsLockActivated = (event) => {
    if (
      event.getModifierState
      && event.getModifierState('CapsLock')
      && this.state.isCapsLockActive === false
    ) {
      this.setState({ isCapsLockActive: true });
    }
  }

  wasCapsLockDeactivated = (event) => {
    if (
      event.getModifierState
      && !event.getModifierState('CapsLock')
      && this.state.isCapsLockActive === true
    ) {
      this.setState({ isCapsLockActive: false });
    }
  }

  render() {
    return this.props.children(this.state.isCapsLockActive);
  }
}

export default CapsLockIndicator;
