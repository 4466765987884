import Logger from '../loggers/logger';

class DispatcherBase {
  constructor(props) {
    this.name = props.name;
    this.listeners = {};
    this.incommingEvents = new Bacon.Bus();

    this.incommingEvents.onValue(this.sendToListener.bind(this));
    this.publish = TP.func.curry(this.publish.bind(this));
  }

  register(key) {
    if (!this.listeners[key]) {
      this.listeners[key] = new Bacon.Bus();
    }
    return this.listeners[key].toEventStream();
  }

  registerAll(keys) {
    return TP.array.reduce(
      (all, key) => {
        all[key] = this.register(key); // eslint-disable-line no-param-reassign
        return all;
      },
      {},
      keys
    );
  }

  sendToListener(update) {
    if (!this.listeners[update.key]) {
      Logger.warning(`[DispatcherBase:${this.name}]:Cannot find listener with ${update.key}`, update.event);
      return;
    }

    const listener = this.listeners[update.key];
    listener.push(update.event);
  }

  publish(key, event) {
    this.incommingEvents.push({ key, event });
  }
}

export default DispatcherBase;
