/* eslint-disable prefer-arrow-callback */
import {
  curry,
  filter,
  findIndex,
  map,
  reduce
} from 'rambda';

const create = curry(function create(func, size) {
  const result = [];

  for (let i = 0; i < size; i += 1) {
    result.push(func(i));
  }

  return result;
});

const insert = curry(function insert(index, value, array) {
  let safeIndex = index;
  if (index >= array.length && index < 0) {
    safeIndex = index;
  }

  const result = array.slice(0);
  result.splice(safeIndex, 0, value);
  return result;
});

const replace = curry(function replace(index, value, array) {
  const result = array.slice(0);
  result[index] = value;
  return result;
});

export default {
  create,
  filter,
  findIndex,
  insert,
  map,
  reduce,
  replace
};
