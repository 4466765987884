// IMPORTS
import { ActionDispatcher } from 'viper';

import { ConnectorsController } from 'service-layer/connectors';

// ACTIONS
const settingsAction = ActionDispatcher.register('customerConnectorSettings');
const addressAction = ActionDispatcher.register('customerConnectorAddress');
const tcAcceptedAction = ActionDispatcher.register('customerConnectorsAzureTCAccepted');
const azureTCDetails = ActionDispatcher.register('customerConnectorAzureTCDetails');
const customerAzureDomainAction = ActionDispatcher.register('customerAzureDomain');
const customerAzureDomainAdfsAction = ActionDispatcher.register('customerAzureDomainAdfs');

// FUNCTIONS
const toDomainChange = (action) => {
  return {
    id: action.id,
    inAzure: action.value
  };
};

const toAdfsDomainChange = (action) => {
  return {
    id: action.id,
    attributes: {
      isAdfsEnabled: action.value
    }
  };
};

// STREAMS
const fieldEditUpdates = Bacon.mergeAll(
  settingsAction,
  addressAction,
  azureTCDetails
)
  .map(TP.object.fromFormField);

const tcAcceptedUpdates = tcAcceptedAction
  .map(TP.object.fromValue('tcAccepted'));

const connectorUpdates = Bacon.mergeAll(
  fieldEditUpdates,
  tcAcceptedUpdates
);

const domainChangeUpdates = Bacon.mergeAll(
  customerAzureDomainAction.map(toDomainChange),
  customerAzureDomainAdfsAction.map(toAdfsDomainChange)
);

// SUBSCRIBERS
connectorUpdates.onValue(ConnectorsController.editPrimaryAzureConnector);
domainChangeUpdates.onValue(ConnectorsController.updateAzureDomain);

// EXPORTS
