import StateReader from 'decorators/state-reader';
import ActionPublisher from 'decorators/action-publisher';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextInput from 'components/text-input';

import SearchResults from './spotlight.search.results';
import {
  stickyInput,
  dialogIndex,
  dialogSize
} from '../styles/spotlight.search.scss';

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

class SpotlightSearch extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.openSearch = this.openSearch.bind(this);
  }

  handleClose() {
    this.publishAction({ type: Constants.actionTypes.CLOSE });
  }

  openSearch() {
    this.publishAction({ type: Constants.actionTypes.OPEN });
  }

  render() {
    return (
      <div className="new-ui">
        <div className="auto-complete global-search">
          <div className="auto-complete-edit">
            <div className="form-group mb-3 border border-light rounded bg-white pointer" onClick={this.openSearch}>
              <div className="input-group">
                <div className="input-group-prepend width-100">
                  <span className="input-group-text rounded-left border-top-0 border-bottom-0 border-left-0 border-right">
                    <i className="fas fa-search" />
                  </span>
                  <span className="text-light m-2">Search</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={!!this.state.currentState.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
          className={dialogIndex}
          scroll="paper"
        >
          <DialogTitle>
            <TextInput
              id="keyword"
              label="Search"
              margin="normal"
              fullWidth
              actionKey="spotlightSearchText"
              autoFocus
              className={stickyInput}
            />
          </DialogTitle>
          <DialogContent className={dialogSize}>
            <form>
              <SearchResults stateKey="spotlightSearchResults" />
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default TP.func.pipe(
  StateReader({ stateKey: 'spotlightSearch' }),
  ActionPublisher({ actionKey: 'spotlightSearch' })
)(SpotlightSearch);

// export default TP.func.pipe(
//   StateReader({ stateKey: 'spotlightSearch' }, SpotlightSearch),
//   Focus({ })
// );
