import ActionPublisher from 'decorators/action-publisher';
import StateReader from 'decorators/state-reader';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class AlertDialog extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onSubmit() {
    this.publishAction({ type: Constants.actionTypes.SUBMIT });
  }

  onCancel() {
    this.publishAction({ type: Constants.actionTypes.CANCEL });
  }

  renderDisagreeButton() {
    if (this.props.disagreeButtonText !== '') {
      return (
        <Button onClick={this.onCancel} color="primary">
          {this.props.disagreeButtonText}
        </Button>
      );
    }

    return (
      null
    );
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.state.currentState.visible}
          onClose={this.onCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {this.renderDisagreeButton()}
            <Button
              onClick={this.props.disagreeButtonText !== ''
                ? this.onSubmit
                : this.onCancel}
              color="primary"
              autoFocus
            >
              {this.props.agreeButtonText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AlertDialog.defaultProps = {
  type: 'dialog'
};

export default TP.func.pipe(
  ActionPublisher({}),
  StateReader({ defaultState: { visible: false } })
)(AlertDialog);
