// IMPORTS
import ApiController from './account.api.controller';

// FUNCTIONS
const changePasswordSync = (changePasswordData, cb) => {
  ApiController.changePasswordSync(changePasswordData, cb);
};

const changePassword = (changePasswordData) => {
  return Bacon.fromNodeCallback(changePasswordSync, changePasswordData);
};

const getAccountSync = (_, cb) => {
  ApiController.getAccountSync(_, cb);
};

const getAccount = (_) => {
  return Bacon.fromNodeCallback(getAccountSync, _);
};

const getMFAConfigSync = (configData, cb) => {
  ApiController.getMFAConfigSync(configData, cb);
};

const getMFAConfig = (configData) => {
  return Bacon.fromNodeCallback(getMFAConfigSync, configData);
};

const loginSync = (loginData, cb) => {
  ApiController.loginSync(loginData, cb);
};

const login = (loginData) => {
  return Bacon.fromNodeCallback(loginSync, loginData);
};

const loginAAD = () => {
  return ApiController.loginAAD();
};

const registerSync = (registerData, cb) => {
  ApiController.registerSync(registerData, cb);
};

const register = (registerData) => {
  return Bacon.fromNodeCallback(registerSync, registerData);
};

const verifySync = (verifyData, cb) => {
  ApiController.verifySync(verifyData, cb);
};

const verify = (verifyData) => {
  return Bacon.fromNodeCallback(verifySync, verifyData);
};

// EXPORTS
export default {
  changePassword,
  getAccount,
  getMFAConfig,
  getMFAConfigSync,
  login,
  loginAAD,
  loginSync,
  register,
  verify
};
