// IMPORTS
import ApiController from './spotlight-search.api.controller';

// FUNCTIONS
const getSearchResults = (searchData) => {
  return ApiController.getSearchResults(searchData);
};

// EXPORTS
export default {
  getSearchResults
};
