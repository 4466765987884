// IMPORTS
import Cookies from 'universal-cookie';
import { StateStore } from 'viper';

import { LoginUpdates } from './login.form.store';
import { MFASuccessUpdates } from './login.mfa.store';
import { ChangePasswordCompleteUpdates } from './change.password.store';

// ACTIONS

// FUNCTIONS
const toMfaState = () => {
  return {
    view: 'mfa'
  };
};

const toChangePasswordState = () => {
  return {
    view: 'changePassword'
  };
};

const doRedirect = (loginResult) => {
  const cookies = new Cookies();
  cookies.set('sessionToken', loginResult.sessionToken, { path: '/', secure: true });
  window.top.location = loginResult.url;
};

// STREAMS
const twoFactorUpdates = LoginUpdates
  .filter(TP.object.propEq('signInStatus', 'RequiresVerification'))
  .map(toMfaState);

const loginCompleteUpdates = Bacon.mergeAll(
  LoginUpdates.filter(TP.object.not.propEq('signInStatus', 'RequiresVerification')),
  MFASuccessUpdates
);

const redirectUpdates = loginCompleteUpdates
  .filter(TP.object.propEq('accountStatus', 'Enabled'))
  .merge(ChangePasswordCompleteUpdates);

const changePasswordUpdates = loginCompleteUpdates
  .filter(TP.object.propEq('accountStatus', 'PasswordExpired'))
  .map(toChangePasswordState);


const loginViewStateUpdates = Bacon.mergeAll(
  twoFactorUpdates,
  changePasswordUpdates
);

// SUBSCRIBERS
loginViewStateUpdates.onValue(StateStore.publish('loginView'));
redirectUpdates.onValue(doRedirect);

// EXPORTS
