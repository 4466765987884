/*
  currentState is an objec that contains stateKeys as properties and state as values
  {
    sampleKey: { sampleValue: 1, version: 1}
  }
*/
class StateStoreBase {
  constructor() {
    this.version = 1;
    this.incommingStateUpdates = new Bacon.Bus();

    this.rawState = this.incommingStateUpdates
      .toEventStream()
      .scan({ update: {}, state: {}, version: this.version }, this.applyStateUpdates.bind(this));

    this.currentState = this.rawState.map(TP.object.prop('state'));
    // bind methods to context
    this.addVersion = this.addVersion.bind(this);
    this.publish = TP.func.curry(this.publish.bind(this));
    this.publishAll = TP.func.curry(this.publishAll.bind(this));
  }

  addVersion(stateValue) {
    return TP.object.assoc('version', this.version, stateValue);
  }

  applyPublish(state, change) {
    return TP.object.assoc(
      change.key,
      this.addVersion(change.value),
      state
    );
  }

  applyPublishAll(state, change) {
    return TP.object.mergeMap(this.addVersion, state, change);
  }

  applyStateUpdates(currentState, update) {
    this.version += 1;
    let { state } = currentState;

    if (this[update.func]) {
      state = this[update.func](currentState.state, update.change);
    }

    return {
      update,
      state,
      version: this.version
    };
  }

  publish(key, value) {
    this.incommingStateUpdates.push({
      func: 'applyPublish',
      change: { key, value }
    });
  }

  publishAll(change) {
    this.incommingStateUpdates.push({
      func: 'applyPublishAll',
      change
    });
  }
}

export default StateStoreBase;
