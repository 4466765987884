// IMPORTS
import {
  ActionDispatcher,
  FocusStore,
  StateStore
} from 'viper';

import { AccountController } from 'service-layer/account';

import {
  LoginUpdates
} from './login.form.store';

// ACTIONS
const loginMFAAction = ActionDispatcher.register('loginMFA');

// FUNCTIONS
const toFormData = (mfaUpdate, mfaForm) => {
  return {
    isRegister: mfaUpdate.requireMfaRegistration,
    data: {
      token: mfaForm.values.mfaCode
    }
  };
};

const toVerificationFailure = (verificationResult) => {
  return {
    errorMessage: verificationResult.friendlyMessage,
    processing: false
  };
};

const toConfigFailure = (configResponse) => {
  return {
    errorMessage: configResponse.friendlyMessage,
    processing: false
  };
};

const toRegisterFailure = (registerResponse) => {
  return {
    errorMessage: registerResponse.friendlyMessage,
    processing: false
  };
};

const toRegistrationLoadingState = () => {
  return {
    errorMessage: '',
    processing: true,
    register: true
  };
};

const toRegistrationState = (config) => {
  return {
    errorMessage: '',
    processing: false,
    register: true,
    qrCodeUrl: config.qrCodeUrl
  };
};

// STREAMS
const twoFactorUpdates = LoginUpdates
  .filter(TP.object.propEq('signInStatus', 'RequiresVerification'));

const submitUpdates = loginMFAAction
  .filter(TP.actions.isSubmit);

const formDataUpdates = Bacon.when(
  [twoFactorUpdates.toProperty(), submitUpdates], toFormData
);

const verifyRequestUpdates = formDataUpdates
  .filter(TP.object.not.prop('isRegister'))
  .map(TP.object.prop('data'))
  .flatMap(AccountController.verify);

const registerRequestUpdates = formDataUpdates
  .filter(TP.object.prop('isRegister'))
  .map(TP.object.prop('data'))
  .flatMap(AccountController.register);

const requireRegistrationUpdates = twoFactorUpdates
  .filter(TP.object.prop('requireMfaRegistration'));

const mfaConfigUpdates = requireRegistrationUpdates
  .flatMap(AccountController.getMFAConfig)
  .map(TP.object.prop('data'))
  .map(toRegistrationState);

const errorUpdates = Bacon.mergeAll(
  verifyRequestUpdates.errors().mapError(toVerificationFailure),
  mfaConfigUpdates.errors().mapError(toConfigFailure),
  registerRequestUpdates.errors().mapError(toRegisterFailure),
  mfaConfigUpdates
);

const mfaViewUpdates = Bacon.mergeAll(
  errorUpdates,
  requireRegistrationUpdates.map(toRegistrationLoadingState)
)
  .scan({}, TP.object.merge);

const mfaSuccessUpdates = verifyRequestUpdates.merge(registerRequestUpdates)
  .skipErrors()
  .map(TP.object.prop('data'));

const focusUpdates = twoFactorUpdates
  .merge(verifyRequestUpdates.errors().mapError(TP.func.identity))
  .map(TP.func.always({ focusKey: 'loginMFA', field: 'mfaCode' }));

// SUBSCRIBERS
mfaViewUpdates.onValue(StateStore.publish('loginMFA'));
focusUpdates.onValue(FocusStore.setFocus);

// EXPORTS
export const MFASuccessUpdates = mfaSuccessUpdates;
