/* eslint-disable no-console */
const consoleLogger = {};

const swallow = () => {};

if (process.env.NODE_ENV !== 'production') {
  consoleLogger.debug = console.debug.bind(console) || ((msg, data) => { console.log(`DEBUG: ${msg}`, data); });
} else {
  consoleLogger.debug = swallow;
}

if (process.env.NODE_ENV !== 'production') {
  consoleLogger.error = console.error.bind(console) || ((msg, data) => { console.log(`ERROR: ${msg}`, data); });
} else {
  consoleLogger.error = swallow;
}

if (process.env.NODE_ENV !== 'production') {
  consoleLogger.info = console.info.bind(console) || ((msg, data) => { console.log(`INFO: ${msg}`, data); });
} else {
  consoleLogger.info = swallow;
}

if (process.env.NODE_ENV !== 'production') {
  consoleLogger.warning = console.warn.bind(console) || ((msg, data) => { console.log(`WARNING: ${msg}`, data); });
} else {
  consoleLogger.warning = swallow;
}

export default consoleLogger;
