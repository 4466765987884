import SuperAgent from 'superagent';
import Uuid from 'uuid';

const REQUEST_TYPE_GET = 'GET';
const REQUEST_TYPE_POST = 'POST';
const REQUEST_TYPE_PUT = 'PUT';
const REQUEST_TYPE_DELETE = 'DELETE';

const addStandardHeaders = (request) => {
  return request
    .type('application/json')
    .accept('application/json')
    .set('X-Correlation-ID', Uuid.v4());
};

const createRequest = (method, url, data) => {
  return Bacon.fromBinder(((sink) => {
    let request = null;
    switch (method) {
      case REQUEST_TYPE_GET:
        request = SuperAgent.get(url);
        break;
      case REQUEST_TYPE_POST:
        request = SuperAgent.post(url)
          .send(data);
        break;
      case REQUEST_TYPE_PUT:
        request = SuperAgent.put(url)
          .send(data);
        break;
      case REQUEST_TYPE_DELETE:
        request = SuperAgent.del(url);
        break;
      default:
        throw new Error('Invalid request method');
    }

    request = addStandardHeaders(request);
    request.end((err, response) => {
      if (err) {
        sink(new Bacon.Error(err));
      } else if (!response.ok) {
        sink(new Bacon.Error(response.text));
      } else {
        sink(new Bacon.Next(TP.object.assoc('status', response.status, response.body)));
      }
    });

    return () => {
      request.abort();
    };
  }));
};

const get = (url) => {
  return createRequest(REQUEST_TYPE_GET, url);
};

const post = (url, data) => {
  return createRequest(REQUEST_TYPE_POST, url, data);
};

const put = (url, data) => {
  return createRequest(REQUEST_TYPE_PUT, url, data);
};

const del = (url) => {
  return createRequest(REQUEST_TYPE_DELETE, url);
};

const addDefaultListeners = (requestStream, cb, defaultErrorMsg) => {
  requestStream.onError((err) => {
    const data = TP.object.path(['response', 'body'], err) || {};
    cb && cb({ friendlyMessage: data.friendlyMessage || defaultErrorMsg, err, data }, null);
  });

  requestStream.onValue((response) => {
    cb && cb(null, response);
  });
};

export default {
  get,
  post,
  put,
  del,
  addDefaultListeners
};
