import StateReader from 'decorators/state-reader';

import Button from 'components/button';

import {
  row,
  buttonRow,
  button
} from '../styles/connector.azure.scss';

class AzureConnectorConnecting extends React.Component {
  componentDidMount() {
    VB.resizeIframe();
  }

  componentDidUpdate() {
    VB.resizeIframe();
  }

  render() {
    return (
      <div>
        <div className={row}>
          The initial sync for the azure customer failed. Press sync below to retry.
        </div>
        <div className={buttonRow}>
          <div className={button}>
            <Button
              defaultKey="azureConnectorConnectRetry"
              type="primary"
              variant="contained"
              text="Retry Sync"
              contentId="retrySync"
              disableOnFirstClick
            />
          </div>
          <div className={row}>
            <b>Error Details:</b>
            <br />
            {this.state.currentState.provisioningErrorMessage}
          </div>
        </div>
      </div>
    );
  }
}

export default StateReader({}, AzureConnectorConnecting);
