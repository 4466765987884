// IMPORTS
import PartnerCenterRepository from '../repositories/msol.partnercenter.repository';
import RegionsRepository from '../repositories/msol.regions.repository';

import ApiController from './msol.api.controller';

// FUNCTIONS
const load = (_, cb) => {
  ApiController.listPartnerCenters(_, (err, response) => {
    if (err) {
      cb && cb(err, response.data);
      return;
    }

    PartnerCenterRepository.refresh(response.data, cb);
  });
};

const select = (selectData, cb) => {
  PartnerCenterRepository.select(selectData, cb);
};

const save = (partnerEditData, cb) => {
  select({});

  const reqCallback = (err, response) => {
    if (err) {
      cb && cb(err, response);
      return;
    }

    const edit = TP.object.pick([
      'label',
      'tier',
      'regionOptionId',
      'regionName',
      'partnerId',
      'applicationId',
      'userId',
      'id',
      'tenantUserName',
      'tenantTestUserName'
    ], response.data);
    PartnerCenterRepository.upsert({ id: partnerEditData.id, edit });
  };

  if (partnerEditData.isNew) {
    ApiController.createPartnerCenter(partnerEditData, reqCallback);
  } else {
    ApiController.updatePartnerCenter(partnerEditData, reqCallback);
  }
};

const remove = (partnerEditData, cb) => {
  select({});

  const reqCallback = (err, response) => {
    if (err) {
      cb && cb(err, response);
      return;
    }

    PartnerCenterRepository.remove({ id: partnerEditData.id });
  };

  ApiController.deletePartnerCenter(partnerEditData, reqCallback);
};

const clearSelection = () => {
  select({});
};

const loadRegions = (_, cb) => {
  ApiController.getPartnerCenterRegions(_, (err, response) => {
    if (err) {
      cb && cb(err, response.data);
      return;
    }

    RegionsRepository.refresh(response.data, cb);
  });
};

// EXPORTS
export default {
  load,
  loadRegions,
  select,
  save,
  remove,
  clearSelection
};
