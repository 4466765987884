// IMPORTS
import {
  ActionDispatcher,
  StateStore
} from 'viper';

import {
  MsolOffersController,
  MsolOffersRepository
} from 'service-layer/msol';

// ACTIONS
const editAction = ActionDispatcher.register('msolOfferEdit');
const saveCancelAction = ActionDispatcher.register('msolOffer');

// FUNCTIONS
const toVisibleState = (offersData) => {
  if (!offersData.selected) {
    return {
      visible: false,
      id: '',
      label: '',
      sku: '',
      isEnabled: false
    };
  }

  const offer = offersData.getSelected();
  return {
    visible: true,
    id: offer.id,
    name: offer.name,
    label: offer.label,
    sku: offer.sku,
    isEnabled: offer.isEnabled
  };
};

const toSaveData = (offer) => {
  return TP.object.pick(['id', 'label', 'sku', 'isEnabled'], offer);
};

// STREAMS
const visibleUpdates = MsolOffersRepository
  .itemsUpdates
  .map(toVisibleState);

const editUpdates = editAction
  .map(TP.object.fromFormField);

const offerUpdates = Bacon.mergeAll(
  visibleUpdates,
  editUpdates
).scan({ visible: false }, TP.object.merge);

const saveUpdates = Bacon.when(
  [offerUpdates.toProperty(), saveCancelAction.filter(TP.actions.isSubmit)], toSaveData
);

const clearSelectionUpdates = saveUpdates.map({});

// SUBSCRIBERS
offerUpdates.onValue(StateStore.publish('msolOffer'));
saveUpdates.onValue(MsolOffersController.saveOffer);
clearSelectionUpdates.onValue(MsolOffersController.selectOffer);
// EXPORTS
