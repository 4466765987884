import {
  always,
  curry,
  identity,
  pipe
} from 'rambda';

const toAsync = (func) => {
  return (...argsToPass) => {
    return Bacon.fromNodeCallback((cb) => {
      func(...argsToPass, cb);
    });
  };
};

export default {
  always,
  curry,
  identity,
  pipe,
  toAsync
};
