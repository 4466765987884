import ItemsDataSource from './items.data.source';
import Merge from './merge';
import ItemsRepositoryOperationBase from './items.repository.operation.base';

const toDataSource = (cleaned, result) => {
  const ds = new ItemsDataSource();
  ds.loadFromMerge(cleaned, result);
  return ds;
};

class ItemsRepository {
  constructor(config) {
    this.config = config;
    this.merge = new Merge({
      toDataSource,
      name: config.name,
      OperationsClass: config.OperationsClass || ItemsRepositoryOperationBase
    });
    this.incommingUpdates = new Bacon.Bus();

    this.rawUpdates = this.incommingUpdates
      .scan(new ItemsDataSource(), this.merge.exec);

    this.itemsUpdates = this.rawUpdates
      .skipDuplicates();

    this.pushUpdate = this.pushUpdate.bind(this);
    this.refresh = this.refresh.bind(this);
    this.select = this.select.bind(this);
    this.edit = this.edit.bind(this);
    this.clear = this.clear.bind(this);
    this.upsert = this.upsert.bind(this);
    this.insert = this.insert.bind(this);
    this.remove = this.remove.bind(this);
  }

  pushUpdate(type, data, cb) {
    this.incommingUpdates.push({ type, data, cb });
  }

  refresh(data, cb) {
    this.pushUpdate('refresh', data, cb);
  }

  select(data, cb) {
    this.pushUpdate('select', data, cb);
  }

  edit(data, cb) {
    this.pushUpdate('edit', data, cb);
  }

  clear(data, cb) {
    this.pushUpdate('clear', data, cb);
  }

  insert(data, cb) {
    this.pushUpdate('insert', data, cb);
  }

  upsert(data, cb) {
    this.pushUpdate('upsert', data, cb);
  }

  remove(data, cb) {
    this.pushUpdate('remove', data, cb);
  }
}

export default ItemsRepository;
