import Paper from '@material-ui/core/Paper';

import StateReader from 'decorators/state-reader';

import Text from 'components/text';

import Domains from './azure.connector.domains';
import Customer from './azure.connector.customer';
import Terms from './azure.connector.terms';

import {
  content,
  errorContainer,
  errorText
} from '../styles/connector.azure.scss';

class CustomerConnectorAzure extends React.Component {
  renderError() {
    if (!this.state.currentState.errorMessage) return null;

    return (
      <Paper className={errorContainer}>
        <Text className={errorText} type={Text.NORMAL} text={this.state.currentState.errorMessage} />
      </Paper>
    );
  }

  renderForm() {
    return (
      <div>
        <Domains
          expanded
          domains={this.state.currentState.customDomains}
          isAdfsEnabled={this.state.currentState.isAdfsEnabled}
        />
        <Customer
          expanded
          firstName={this.state.currentState.firstName}
          lastName={this.state.currentState.lastName}
          email={this.state.currentState.email}
          address={this.state.currentState.address}
          city={this.state.currentState.city}
          state={this.state.currentState.state}
          countryOptions={this.state.currentState.countryOptions}
          countryCode={this.state.currentState.countryCode}
          phoneNumber={this.state.currentState.phoneNumber}
          postalCode={this.state.currentState.postalCode}
        />
        <Terms
          expanded
          connectorStatusId={this.state.currentState.connectorStatusId}
          tcAccepted={this.state.currentState.tcAccepted}
          tcAcceptedByFirstName={this.state.currentState.tcAcceptedByFirstName}
          tcAcceptedByLastName={this.state.currentState.tcAcceptedByLastName}
          tcAcceptedByEmail={this.state.currentState.tcAcceptedByEmail}
          tcAcceptedByPhoneNumber={this.state.currentState.tcAcceptedByPhoneNumber}
        />
        {this.renderError()}
      </div>
    );
  }

  render() {
    if (!this.state.currentState.loaded) return null;
    return (
      <div className={content}>
        {this.renderForm()}
      </div>
    );
  }
}

export default TP.func.pipe(
  StateReader({ defaultState: { loaded: false } })
)(CustomerConnectorAzure);
