import StateReader from 'decorators/state-reader';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {
  resultCount,
  linkStyle,
  card,
  cardContent,
  result,
  resultTitle,
  iconSearchResult,
  iconPosition,
  divCard
} from '../styles/spotlight.search.scss';

class SpotlightSearchResults extends React.Component {
  renderCustomers() {
    if (!this.state.currentState.customers || this.state.currentState.customers.length < 1) return null;

    return (
      <div>
        <h3>
          CUSTOMERS
        </h3>
        {
          TP.array.map((customer) => {
            return (
              <div key={`C_${customer.id}`} className={divCard}>
                <Card className={card}>
                  <CardContent className={cardContent}>
                    <div className={iconPosition}>
                      <i className={`material-icons ${iconSearchResult}`}>domain</i>
                    </div>
                    <div className={result}>
                      <div className={resultTitle}>
                        <a href={`/Customer/Customers.aspx?CustomerID=${customer.id}&filter=clear`}>{customer.label} ({customer.code})</a>
                      </div>
                      <div>
                        <div>{customer.domain}</div>
                        <div>
                          <a
                            className={linkStyle}
                            href={`/Customer/CustomerServices.aspx?CustomerID=${customer.id}`}
                          >
                            Services
                          </a> | <a href={`/User/Users.aspx?CustomerID=${customer.id}`}>Users</a>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            );
          }, this.state.currentState.customers)
        }
      </div>
    );
  }

  renderUsers() {
    if (!this.state.currentState.users || this.state.currentState.users.length < 1) return null;

    return (
      <div>
        <h3>
          USERS
        </h3>
        {
          TP.array.map((user) => {
            return (
              <div key={`U_${user.id}`} className={divCard}>
                <Card className={card}>
                  <CardContent className={cardContent}>
                    <div className={iconPosition}>
                      <i className={`material-icons ${iconSearchResult}`}>account_circle</i>
                    </div>
                    <div className={result}>
                      <div className={resultTitle}>
                        <a href={`/User/Users.aspx?UserID=${user.id}&CustomerID=${user.relatedId}`}>{user.name}</a>
                      </div>
                      <div>
                        <div>
                          <a href={`/Customer/Customers.aspx?CustomerID=${user.relatedId}&filter=clear`}>{user.label}</a>
                        </div>
                        <div>{user.code} | {user.username}</div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            );
          }, this.state.currentState.users)
        }
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className={resultCount}>
          Result count: {this.state.currentState.totalCount}
        </div>
        {
          this.renderCustomers()
        }
        {
          this.renderUsers()
        }
      </div>
    );
  }
}

export default StateReader({ defaultState: { customers: [], users: [], totalCount: 0 } }, SpotlightSearchResults);
