import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ActionPublisher from 'decorators/action-publisher';
import StateReader from 'decorators/state-reader';

import Button from 'components/button';
import Text from 'components/text';

import {
  container,
  rowHoverStyle
} from '../styles/partner.center.connection.scss';

class MsolPartnerCenterConnections extends React.Component {
  constructor(props) {
    super(props);
    this.selectPartnerCenter = this.selectPartnerCenter.bind(this);
  }

  selectPartnerCenter(evt) {
    const id = evt.target.getAttribute('data-id') || evt.target.parentElement.getAttribute('data-id');
    this.publishAction({ id });
  }

  renderPartnerCenters() {
    return TP.array.map(
      (partnerCenter) => {
        return (
          <TableRow key={partnerCenter.id} data-id={partnerCenter.id} onClick={this.selectPartnerCenter} className={rowHoverStyle}>
            <TableCell>{partnerCenter.label}</TableCell>
            <TableCell>{partnerCenter.regionName}</TableCell>
            <TableCell>{partnerCenter.partnerId}</TableCell>
            <TableCell align="right">{partnerCenter.tier && partnerCenter.tier.replace('Tier', '')}</TableCell>
            <TableCell>{partnerCenter.connectorsCount || 0}</TableCell>
          </TableRow>
        );
      },
      this.state.currentState.items
    );
  }

  render() {
    return (
      <div className={container}>
        <div>
          <Text
            type={Text.HEADING_1}
            text="Partner Center Connections"
            contentId="offerManagementTitle"
          />
        </div>
        <div>
          <Text
            type={Text.HEADING_2}
            text="Setup and manage partner center connections."
            contentId="offerManagementDescription"
          />
        </div>
        <div>
          <Button
            defaultKey="msolPartnerCenterNew"
            type="primary"
            variant="text"
            text="New Partner Center Connection"
            contentId="newPCConnection"
          />
        </div>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Partner Center</TableCell>
                <TableCell>Region</TableCell>
                <TableCell>MS Partner ID</TableCell>
                <TableCell align="right">Tier</TableCell>
                <TableCell>Connectors Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.renderPartnerCenters()}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

export default TP.func.pipe(
  ActionPublisher({}),
  StateReader({})
)(MsolPartnerCenterConnections);
