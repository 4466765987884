import ActionPublisher from 'decorators/action-publisher';
import Focus from 'decorators/focus';
import Form from 'decorators/form';
import StateReader from 'decorators/state-reader';

import TextInput from 'components/text-input';
import Button from 'components/button';

import {
  footer,
  formContainer,
  heading,
  loginInputs
} from '../styles/login.form.scss';

class ChangePassword extends React.Component {
  renderError() {
    if (!this.state.currentState.errorMessage) return null;
    return (
      <div style={{ color: 'red' }}>
        {this.state.currentState.errorMessage}
      </div>
    );
  }

  render() {
    return (
      <div className={formContainer}>
        <h2
          className={heading}
        >
          Change Password
        </h2>
        <span>
          Your password has expired and needs to be changed.  Choose a strong password, click the help icon for help on this and the requirements.
        </span>
        {this.renderFormField(
          TextInput,
          'currentPassword',
          true,
          TP.validators.notEmpty,
          { className: loginInputs, label: 'Current Password', type: 'password' }
        )}
        {this.renderFormField(
          TextInput,
          'newPassword',
          true,
          TP.validators.notEmpty,
          { className: loginInputs, label: 'New Password', type: 'password' }
        )}
        {this.renderFormField(
          TextInput,
          'confirmNewPassword',
          true,
          TP.validators.notEmpty,
          { className: loginInputs, label: 'Confirm New Password', type: 'password' }
        )}
        {this.renderError()}
        <div className={footer}>
          <Button
            disabled={this.isProcessing()}
            onPublish={this.onSubmit}
            type="primary"
            variant="contained"
            text="Change Password"
          />
        </div>
      </div>
    );
  }
}

export default TP.func.pipe(
  StateReader({ }),
  ActionPublisher({ }),
  Form({}),
  Focus({})
)(ChangePassword);
