import Grid from '@material-ui/core/Grid';
import ActionPublisher from 'decorators/action-publisher';
import StateReader from 'decorators/state-reader';

import Button from 'components/button';
import RadioList from 'components/radio-list';
import TextInput from 'components/text-input';
import Text from 'components/text';

import AlertDialog from 'components/alert-dialog';
import RegionSelect from './msol.region.select';

import {
  container
} from '../styles/partner.center.connection.scss';

const partnerTypeItems = [
  { value: 'Tier1', label: 'Tier 1 CSP' },
  { value: 'Tier2', label: 'Tier 2 CSP' }
];

class MsolPartnerCenterConnection extends React.Component {
  renderRegion() {
    if (this.state.currentState.isNew) {
      return (
        <RegionSelect
          defaultKey="msolRegions"
          key={this.state.currentState.regionOptionId || 0}
          value={this.state.currentState.regionOptionId}
        />
      );
    }

    return (
      <Text
        type={Text.FORM_FIELD_VALUE}
        domComponent="div"
        text={this.state.currentState.regionName}
      />
    );
  }

  renderDeleteDialog() {
    return (
      <AlertDialog
        title={this.state.currentState.connectorsCount === 0 ? 'Delete Partner Center' : 'Unable to Delete'}
        description={this.state.currentState.connectorsCount === 0
          ? 'Are you sure you want to delete this partner center connection?'
          : 'Partner Center Connection is currently in use.'}
        disagreeButtonText={this.state.currentState.connectorsCount === 0 ? 'No' : ''}
        agreeButtonText={this.state.currentState.connectorsCount === 0 ? 'Yes' : 'OK'}
        defaultKey="deleteDialogConfirm"
      />
    );
  }

  render() {
    return (
      <div className={container}>
        <Grid container spacing={Number(3)}>
          <Grid item xs={12}>
            <Text type={Text.HEADING_1} contentId="pcConnectionSetup" text="Partner Center Connection Setup" />
          </Grid>
          <Grid item xs={6}>
            <Grid container alignItems="center" spacing={Number(3)}>
              <Grid item xs={12} style={{ marginTop: '25px', paddingBottom: 0 }}>
                <Text type={Text.HEADING_2} contentId="pcConnectionSettings" text="Settings" />
              </Grid>
              <Grid item xs={3}>
                <Text
                  type={Text.FORM_LABEL_BOLD}
                  domComponent="div"
                  contentId="label"
                  text="Label"
                />
              </Grid>
              <Grid item xs={9}>
                <TextInput
                  field="label"
                  actionKey="partnerCenterEdit"
                  validator={TP.validators.notEmpty}
                  value={this.state.currentState.label}
                />
              </Grid>
              <Grid item xs={3}>
                <Text
                  type={Text.FORM_LABEL_BOLD}
                  domComponent="div"
                  contentId="PartnerType"
                  text="Partner Type"
                />
              </Grid>
              <Grid item xs={9}>
                <RadioList
                  actionKey="partnerCenterEdit"
                  items={partnerTypeItems}
                  value={this.state.currentState.tier}
                  field="tier"
                />
              </Grid>
              <Grid item xs={3}>
                <Text
                  type={Text.FORM_LABEL_BOLD}
                  domComponent="div"
                  contentId="region"
                  text="Region"
                />
              </Grid>
              <Grid item xs={9}>
                {this.renderRegion()}
              </Grid>
              <Grid item xs={3}>
                <Text
                  type={Text.FORM_LABEL_BOLD}
                  domComponent="div"
                  contentId="tenantTestUserName"
                  text="Tenant Test User Name"
                />
              </Grid>
              <Grid item xs={9}>
                <TextInput
                  field="tenantTestUserName"
                  actionKey="partnerCenterEdit"
                  validator={TP.validators.notEmpty}
                  fullWidth
                  value={this.state.currentState.tenantTestUserName}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: '25px', paddingBottom: 0 }}>
                <Text type={Text.HEADING_2} contentId="pcConnectionPartnerDetails" text="Partner Details" />
              </Grid>
              <Grid item xs={3}>
                <Text
                  type={Text.FORM_LABEL_BOLD}
                  domComponent="div"
                  contentId="partnerId"
                  text="Partner ID"
                />
              </Grid>
              <Grid item xs={9}>
                <TextInput
                  field="partnerId"
                  actionKey="partnerCenterEdit"
                  validator={TP.validators.notEmpty}
                  fullWidth
                  value={this.state.currentState.partnerId}
                />
              </Grid>
              <Grid item xs={3}>
                <Text
                  type={Text.FORM_LABEL_BOLD}
                  domComponent="div"
                  contentId="userId"
                  text="Partner User ID"
                />
              </Grid>
              <Grid item xs={9}>
                <TextInput
                  field="userId"
                  actionKey="partnerCenterEdit"
                  validator={TP.validators.notEmpty}
                  fullWidth
                  value={this.state.currentState.userId}
                />
              </Grid>
              <Grid item xs={3}>
                <Text
                  type={Text.FORM_LABEL_BOLD}
                  domComponent="div"
                  contentId="partnerUserName"
                  text="Partner User Name"
                />
              </Grid>
              <Grid item xs={9}>
                <TextInput
                  field="partnerUserName"
                  actionKey="partnerCenterEdit"
                  validator={TP.validators.notEmpty}
                  fullWidth
                  value={this.state.currentState.partnerUserName}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container alignItems="center" spacing={Number(3)}>
              <Grid item xs={12} style={{ marginTop: '25px', paddingBottom: 0 }}>
                <Text contentId="Partner Center Application" text="Partner Center Application" type={Text.HEADING_2} />
              </Grid>
              <Grid item xs={12}>
                <Text contentId="powershellInfo">
                  Please run the create-azure-app.ps1 script to get the values for Application ID, Application Secret and Refresh Token
                </Text>
              </Grid>
              <Grid item xs={3}>
                <Text
                  type={Text.FORM_LABEL_BOLD}
                  domComponent="div"
                  contentId="applicationId"
                  text="Application ID"
                />
              </Grid>
              <Grid item xs={9}>
                <TextInput
                  field="applicationId"
                  actionKey="partnerCenterEdit"
                  validator={TP.validators.notEmpty}
                  fullWidth
                  value={this.state.currentState.applicationId}
                />
              </Grid>
              <Grid item xs={3}>
                <Text
                  type={Text.FORM_LABEL_BOLD}
                  domComponent="div"
                  contentId="applicationSecret"
                  text="Application Secret"
                />
              </Grid>
              <Grid item xs={9}>
                <TextInput
                  field="clientSecret"
                  actionKey="partnerCenterEdit"
                  validator={TP.validators.notEmpty}
                  fullWidth
                  value={this.state.currentState.clientSecret}
                />
              </Grid>
              <Grid item xs={3}>
                <Text
                  type={Text.FORM_LABEL_BOLD}
                  domComponent="div"
                  contentId="refreshToken"
                  text="Refresh Token"
                />
              </Grid>
              <Grid item xs={9}>
                <TextInput
                  field="refreshToken"
                  actionKey="partnerCenterEdit"
                  validator={TP.validators.notEmpty}
                  fullWidth
                  value={this.state.currentState.refreshToken}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: '25px', paddingBottom: 0 }}>
                <Text contentId="Exchange Online" text="Exchange Online" type={Text.HEADING_2} />
              </Grid>
              <Grid item xs={12}>
                <Text contentId="powershellInfo">
                  Please run the exchange-online-consent.ps1 script to get the exchange refresh token.
                </Text>
              </Grid>
              <Grid item xs={3}>
                <Text
                  type={Text.FORM_LABEL_BOLD}
                  domComponent="div"
                  contentId="exchangeRefreshToken"
                  text="Exchange Refresh Token"
                />
              </Grid>
              <Grid item xs={9}>
                <TextInput
                  field="exchangeRefreshToken"
                  actionKey="partnerCenterEdit"
                  validator={TP.validators.notEmpty}
                  fullWidth
                  value={this.state.currentState.exchangeRefreshToken}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />

        <Button
          actionKey="partnerCenterSave"
          type="primary"
          variant="contained"
          text="Save"
        />

        <Button
          defaultKey="deleteDialog"
          variant="text"
          text="Delete"
          style={{ marginLeft: '15px' }}
          disabled={this.state.currentState.isNew}
        />

        <Button
          defaultKey="warningDialog"
          variant="text"
          text="Cancel"
          style={{ marginLeft: '15px' }}
        />

        <AlertDialog
          title="Warning!"
          description="Are you sure you want to leave the page? Unsaved data will be lost."
          disagreeButtonText="No"
          agreeButtonText="Yes"
          defaultKey="warningDialogConfirm"
        />

        {this.renderDeleteDialog()}
      </div>
    );
  }
}

export default TP.func.pipe(
  ActionPublisher({ }),
  StateReader({})
)(MsolPartnerCenterConnection);
