import Logger from '../loggers/logger';

class RepositoryOperationBase {
  constructor(config) {
    this.config = config;
  }

  exec(dataSource, change) {
    const func = this[change.type];
    if (!func) {
      Logger.warning(`Failed to find a merge function for ${change.type} in ${this.config.name}OperationsClass`);
      return false;
    }

    const result = func(dataSource, change);

    return result;
  }
}

export default RepositoryOperationBase;
