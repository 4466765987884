import { Api } from 'viper';

const getPrimaryAzureConnector = (customer, cb) => {
  const request = Api.get(`/api/service/msol/customers/${customer.id}`);
  Api.addDefaultListeners(request, cb, 'Failed to retrieve connector for customer');
};

const savePrimaryAzureConnector = (customer, connector, cb) => {
  const request = Api.post(`/api/service/msol/connectors/customers/${customer.id}/azure/primary`, connector);
  Api.addDefaultListeners(request, cb, 'Failed to save and provision the connector');
};

const verifyDomain = (domain, cb) => {
  // eslint-disable-next-line max-len
  const url = `/api/service/msol/customers/${domain.customerId}/domains/prefix-available?prefix=${domain.prefix}&partnerCenterId=${domain.partnerCenterId}`;
  const request = Api.get(url);
  Api.addDefaultListeners(request, cb, 'Failed to verify the domain prefix');
};

const provision = (connector, cb) => {
  const keys = [
    'azureDomain',
    'customDomains',
    'firstName',
    'lastName',
    'email',
    'address',
    'city',
    'state',
    'countryCode',
    'phoneNumber',
    'postalCode',
    'tcAccepted',
    'tcAcceptedByFirstName',
    'tcAcceptedByLastName',
    'tcAcceptedByEmail',
    'tcAcceptedByPhoneNumber',
    'manageUsers',
    'manageLicenses',
    'syncPolicyId'
  ];

  if (connector.isAdfsEnabled) {
    keys.push('adfsPolicyId');
  }

  const provRequestData = TP.object.pick(keys, connector);

  const request = Api.put(`/api/service/msol/customers/${connector.customerId}/provision`, provRequestData);
  Api.addDefaultListeners(request, cb, 'Failed to provision connector.');
};

const connect = (connectData, cb) => {
  const request = Api.post(`/api/service/msol/customers/${connectData.customerId}/connect/${connectData.azureCustomerId}`, {});
  Api.addDefaultListeners(request, cb, 'Failed to connect to existing azure tenant.');
};

const getStatus = (connector, cb) => {
  const request = Api.get(`/api/objects/${connector.objectId}/status`);
  Api.addDefaultListeners(request, cb, 'Failed to poll for status');
};

export default {
  getPrimaryAzureConnector,
  savePrimaryAzureConnector,
  verifyDomain,
  connect,
  provision,
  getStatusAsync: TP.func.toAsync(getStatus)
};
