// IMPORTS
import { ActionDispatcher, StateStore } from 'viper';
import { AccountController } from 'service-layer/account';

// ACTIONS
const moduleMFASettingsRenderedAction = ActionDispatcher.register('modulemfaSettingsRendered');
const mfaSettingsRegisterAction = ActionDispatcher.register('mfaSettingsRegister');
const mfaSettingsResetAction = ActionDispatcher.register('mfaSettingsReset');

// FUNCTIONS
const toErrorState = (err) => {
  return {
    loaded: true,
    processing: false,
    errorMessage: err.friendlyMessage
  };
};

const toMFAState = (account) => {
  const result = TP.object.pick(['mfaRegistered', 'mfaEnabled'], account);
  result.errorMessage = '';
  result.loaded = true;
  return result;
};

const toQrState = () => {
  return {
    view: 'qr'
  };
};

// STREAMS
const accountRequestUpdates = moduleMFASettingsRenderedAction
  .map(TP.func.always({ isReset: true }))
  .flatMap(AccountController.getAccount);

const accountRequestErrors = accountRequestUpdates
  .errors()
  .mapError(toErrorState);

const qrButtonClickUpdates = Bacon.mergeAll(
  mfaSettingsRegisterAction.map(TP.func.always({ isReset: false })),
  mfaSettingsResetAction.map(TP.func.always({ isReset: true }))
);

const qrRequestUpdates = qrButtonClickUpdates
  .flatMap(AccountController.getMFAConfig)
  .map(TP.object.prop('data'));

const qrRequestErrors = qrRequestUpdates
  .errors()
  .mapError(toErrorState);

const qrUpdates = qrRequestUpdates
  .skipErrors();

const qrStateUpdates = qrUpdates
  .map(toQrState);

const mfaUpdates = accountRequestUpdates
  .skipErrors()
  .map(toMFAState);

const stateUpdates = Bacon.mergeAll(
  mfaUpdates,
  accountRequestErrors,
  qrRequestErrors,
  qrStateUpdates,
  qrButtonClickUpdates.map(TP.func.always({ processing: true }))
)
  .scan({ loaded: false, view: 'status' }, TP.object.merge);

// SUBSCRIBERS
stateUpdates.onValue(StateStore.publish('mfaSettings'));

// EXPORTS
export const QrUpdates = qrUpdates;
