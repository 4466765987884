import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialCheckbox from '@material-ui/core/Checkbox';

import ActionPublisher from 'decorators/action-publisher';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(evt) {
    const data = {
      field: this.props.field,
      type: Constants.actionTypes.CHANGE,
      value: evt.target.checked
    };

    if (this.props.validator) {
      data.valid = this.props.validator(data);
    }

    this.publishAction(data);
  }

  render() {
    return (
      <FormControlLabel
        control={(
          <MaterialCheckbox
            checked={this.props.checked}
            onChange={this.onChange}
            disabled={this.props.disabled}
          />
        )}
        label={this.props.text}
      />
    );
  }
}

export default TP.func.pipe(
  ActionPublisher({})
)(Checkbox);
