// IMPORTS
import ApiController from './msol.api.controller';

import OffersRepository from '../repositories/msol.offers.repository';
import PartnerCenterRepository from '../repositories/msol.partnercenter.repository';

// FUNCTIONS
const clearOffers = (_, cb) => {
  OffersRepository.clear({}, cb);
};

const loadOffers = (partnerCenter, cb) => {
  ApiController.listOffers(partnerCenter, (err, response) => {
    if (err) {
      cb && cb(err, response);
      return;
    }
    OffersRepository.refresh(response.data, cb);
  });
};

const saveOffer = (offer, cb) => {
  PartnerCenterRepository.itemsUpdates
    .take(1)
    .onValue((partnerCenters) => {
      ApiController.saveOffer(partnerCenters.getSelected(), offer, (err, response) => {
        if (err) {
          cb && cb(err, response);
          return;
        }

        const edit = TP.object.pick(['label', 'sku', 'isEnabled'], offer);
        OffersRepository.edit({ id: offer.id, edit }, cb);
      });
    });
};

const selectOffer = (offer, cb) => {
  OffersRepository.select(offer, cb);
};

const syncOffers = (partnerCenter, cb) => {
  clearOffers({});

  ApiController.syncOffers(partnerCenter, (err, response) => {
    if (err) {
      cb && cb(err, response);
      return;
    }
    OffersRepository.refresh(response.data, cb);
  });
};

// EXPORTS
export default {
  clearOffers,
  loadOffers,
  selectOffer,
  saveOffer,
  syncOffers
};
